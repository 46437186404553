/* tslint:disable */
/* eslint-disable */
/**
 * SWFPA Vessel Crewing Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgencyDocumentModel
 */
export interface AgencyDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentModel
     */
    agencyId?: string;
    /**
     * 
     * @type {any}
     * @memberof AgencyDocumentModel
     */
    document?: any | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentModel
     */
    agencyDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface AgencyDocumentUpdateModel
 */
export interface AgencyDocumentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyDocumentUpdateModel
     */
    filename: string;
}
/**
 * 
 * @export
 * @interface AgencyModel
 */
export interface AgencyModel {
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    contactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    contactEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    licenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    postalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    companyRegistrationNumber?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof AgencyModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    countryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    modifiedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface AgencyUpsertModel
 */
export interface AgencyUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    contactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    contactEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    licenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    postalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgencyUpsertModel
     */
    companyRegistrationNumber?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof AgencyUpsertModel
     */
    discriminator?: ContractType;
}
/**
 * 
 * @export
 * @interface AuthorisationModel
 */
export interface AuthorisationModel {
    /**
     * 
     * @type {string}
     * @memberof AuthorisationModel
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorisationModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface CertificateTypeModel
 */
export interface CertificateTypeModel {
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeModel
     */
    name: string;
    /**
     * 
     * @type {ContractType}
     * @memberof CertificateTypeModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {number}
     * @memberof CertificateTypeModel
     */
    validityInDays?: number;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeModel
     */
    certificateTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeModel
     */
    modifiedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface CertificateTypeUpsertModel
 */
export interface CertificateTypeUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeUpsertModel
     */
    name: string;
    /**
     * 
     * @type {ContractType}
     * @memberof CertificateTypeUpsertModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {number}
     * @memberof CertificateTypeUpsertModel
     */
    validityInDays?: number;
}
/**
 * 
 * @export
 * @interface CertificateTypeVesselEnquiryCreateModel
 */
export interface CertificateTypeVesselEnquiryCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryCreateModel
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryCreateModel
     */
    certificateTypeId?: string;
}
/**
 * 
 * @export
 * @interface CertificateTypeVesselEnquiryModel
 */
export interface CertificateTypeVesselEnquiryModel {
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryModel
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryModel
     */
    certificateTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryModel
     */
    certificateTypeVesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryModel
     */
    certificateTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CertificateTypeVesselEnquiryModel
     */
    vesselEnquiryName?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ContractType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface CountryModel
 */
export interface CountryModel {
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    nationality: string;
}
/**
 * 
 * @export
 * @interface CrewMemberCertificateModel
 */
export interface CrewMemberCertificateModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberCertificateModel
     */
    dealtWith?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberCertificateModel
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    crewMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    certificateTypeId?: string;
    /**
     * 
     * @type {any}
     * @memberof CrewMemberCertificateModel
     */
    certificate?: any | null;
    /**
     * 
     * @type {ContractType}
     * @memberof CrewMemberCertificateModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    crewMemberCertificateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    crewMemberFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    crewMemberLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    crewMemberFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    certificateTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    currentVessel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberCertificateUpdateModel
 */
export interface CrewMemberCertificateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateUpdateModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateUpdateModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberCertificateUpdateModel
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberCertificateUpdateModel
     */
    dealtWith?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberCertificateUpdateModel
     */
    archived?: boolean;
}
/**
 * 
 * @export
 * @interface CrewMemberDocumentModel
 */
export interface CrewMemberDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    filename: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberDocumentModel
     */
    adminViewOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    crewMemberId?: string;
    /**
     * 
     * @type {ContractType}
     * @memberof CrewMemberDocumentModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {any}
     * @memberof CrewMemberDocumentModel
     */
    document?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    crewMemberDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    crewMemberFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    crewMemberLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    crewMemberFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberDocumentModel
     */
    archived?: boolean;
}
/**
 * 
 * @export
 * @interface CrewMemberDocumentUpdateModel
 */
export interface CrewMemberDocumentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentUpdateModel
     */
    filename: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberDocumentUpdateModel
     */
    adminViewOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentUpdateModel
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberDocumentUpdateModel
     */
    expiryDate?: string | null;
}
/**
 * 
 * @export
 * @interface CrewMemberInCountryModel
 */
export interface CrewMemberInCountryModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof CrewMemberInCountryModel
     */
    data?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface CrewMemberJobRoleCreateModel
 */
export interface CrewMemberJobRoleCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberJobRoleCreateModel
     */
    crewMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberJobRoleCreateModel
     */
    jobRoleId?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberModel
 */
export interface CrewMemberModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    agencyId?: string;
    /**
     * 
     * @type {ContractType}
     * @memberof CrewMemberModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    knownAs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    passportNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    placeOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    passportIssued?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    passportExpiry?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    employmentStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    employmentEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    contactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    alternativeContactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    nextOfKinName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    nextOfKinContactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    nextOfKinAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    nextOfKinRelationship?: string | null;
    /**
     * 
     * @type {Array<CrewMemberJobRoleCreateModel>}
     * @memberof CrewMemberModel
     */
    jobRoles?: Array<CrewMemberJobRoleCreateModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    seamansBookNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    seamansBookIssued?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    seamansBookExpiry?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberModel
     */
    doNotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberModel
     */
    skilledWorker?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CrewMemberModel
     */
    updateNoteDays?: number;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    updateNoteDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CrewMemberModel
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    medicalAlerts?: string | null;
    /**
     * 
     * @type {Array<JobRoleModel>}
     * @memberof CrewMemberModel
     */
    crewMemberJobRoles?: Array<JobRoleModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    crewJobRoles?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    crewMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    dateOfBirthString?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    agencyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    currentVessel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    currentVesselAgencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    currentVesselRss?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    countryNationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    countryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    agencyCountryNationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    modifiedUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    modifiedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    modifiedByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    modifiedByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    modifiedByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberModel
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    lastNote?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberModel
     */
    updateNoteDaysBefore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberModel
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {UserModel}
     * @memberof CrewMemberModel
     */
    linkedUser?: UserModel;
}
/**
 * 
 * @export
 * @interface CrewMemberNoteEvidenceModel
 */
export interface CrewMemberNoteEvidenceModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteEvidenceModel
     */
    crewMemberNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteEvidenceModel
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteEvidenceModel
     */
    crewMemberNoteEvidenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteEvidenceModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteEvidenceModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberNoteModel
 */
export interface CrewMemberNoteModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberNoteModel
     */
    adminViewOnly?: boolean;
    /**
     * 
     * @type {Array<CrewMemberNoteEvidenceModel>}
     * @memberof CrewMemberNoteModel
     */
    crewMemberNoteEvidence?: Array<CrewMemberNoteEvidenceModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    crewMemberId?: string;
    /**
     * 
     * @type {ContractType}
     * @memberof CrewMemberNoteModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {Array<any>}
     * @memberof CrewMemberNoteModel
     */
    evidence?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    crewMemberNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    crewMemberFistname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    crewMemberLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    crewMemberFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberNoteUpdateModel
 */
export interface CrewMemberNoteUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteUpdateModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberNoteUpdateModel
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberNoteUpdateModel
     */
    adminViewOnly?: boolean;
    /**
     * 
     * @type {Array<CrewMemberNoteEvidenceModel>}
     * @memberof CrewMemberNoteUpdateModel
     */
    crewMemberNoteEvidence?: Array<CrewMemberNoteEvidenceModel> | null;
}
/**
 * 
 * @export
 * @interface CrewMemberUpsertModel
 */
export interface CrewMemberUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    agencyId?: string;
    /**
     * 
     * @type {ContractType}
     * @memberof CrewMemberUpsertModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    knownAs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    passportNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    placeOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    passportIssued?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    passportExpiry?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    employmentStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    employmentEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    contactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    alternativeContactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    nextOfKinName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    nextOfKinContactNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    nextOfKinAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    nextOfKinRelationship?: string | null;
    /**
     * 
     * @type {Array<CrewMemberJobRoleCreateModel>}
     * @memberof CrewMemberUpsertModel
     */
    jobRoles?: Array<CrewMemberJobRoleCreateModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    seamansBookNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    seamansBookIssued?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    seamansBookExpiry?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberUpsertModel
     */
    doNotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberUpsertModel
     */
    skilledWorker?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CrewMemberUpsertModel
     */
    updateNoteDays?: number;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    updateNoteDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CrewMemberUpsertModel
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    medicalAlerts?: string | null;
    /**
     * 
     * @type {Array<JobRoleModel>}
     * @memberof CrewMemberUpsertModel
     */
    crewMemberJobRoles?: Array<JobRoleModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberUpsertModel
     */
    crewJobRoles?: string | null;
}
/**
 * 
 * @export
 * @interface CrewMemberVesselEnquiryCreate
 */
export interface CrewMemberVesselEnquiryCreate {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryCreate
     */
    crewMemberId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryCreate
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryCreate
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryCreate
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryCreate
     */
    jobRoleId?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberVesselEnquiryModel
 */
export interface CrewMemberVesselEnquiryModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    crewMemberId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    jobRoleId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    crewMemberVesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    crewMemberFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    crewMemberLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    crewMemberFullname?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CrewMemberVesselEnquiryModel
     */
    crewMemberRating?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    vesselEnquiryVesselName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    vesselEnquiryVesselRss?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    jobRoleName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberVesselEnquiryModel
     */
    available?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberVesselEnquiryModel
     */
    certified?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberVesselEnquiryModel
     */
    validPassport?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    statusDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberVesselEnquiryModel
     */
    validJobRole?: boolean;
}
/**
 * 
 * @export
 * @interface CrewMemberVesselEnquiryUpdateModel
 */
export interface CrewMemberVesselEnquiryUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryUpdateModel
     */
    crewMemberId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryUpdateModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberVesselEnquiryUpdateModel
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface InCountryPerNationalityCollectionModel
 */
export interface InCountryPerNationalityCollectionModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof InCountryPerNationalityCollectionModel
     */
    categories?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InCountryPerNationalityCollectionModel
     */
    data?: Array<number> | null;
    /**
     * 
     * @type {Array<InCountryPerNationalityModel>}
     * @memberof InCountryPerNationalityCollectionModel
     */
    inCountryPerNationalityModels?: Array<InCountryPerNationalityModel> | null;
}
/**
 * 
 * @export
 * @interface InCountryPerNationalityModel
 */
export interface InCountryPerNationalityModel {
    /**
     * 
     * @type {string}
     * @memberof InCountryPerNationalityModel
     */
    nationality?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InCountryPerNationalityModel
     */
    inCountryCount?: number;
}
/**
 * 
 * @export
 * @interface JobRoleModel
 */
export interface JobRoleModel {
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    name: string;
    /**
     * 
     * @type {ContractType}
     * @memberof JobRoleModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    jobRoleId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRoleModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface JobRoleUpsertModel
 */
export interface JobRoleUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof JobRoleUpsertModel
     */
    name: string;
    /**
     * 
     * @type {ContractType}
     * @memberof JobRoleUpsertModel
     */
    discriminator?: ContractType;
}
/**
 * 
 * @export
 * @interface OutCountryPerNationalityCollectionModel
 */
export interface OutCountryPerNationalityCollectionModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof OutCountryPerNationalityCollectionModel
     */
    categories?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OutCountryPerNationalityCollectionModel
     */
    data?: Array<number> | null;
    /**
     * 
     * @type {Array<OutCountryPerNationalityModel>}
     * @memberof OutCountryPerNationalityCollectionModel
     */
    outCountryPerNationalityModels?: Array<OutCountryPerNationalityModel> | null;
}
/**
 * 
 * @export
 * @interface OutCountryPerNationalityModel
 */
export interface OutCountryPerNationalityModel {
    /**
     * 
     * @type {string}
     * @memberof OutCountryPerNationalityModel
     */
    nationality?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OutCountryPerNationalityModel
     */
    outCountryCount?: number;
}
/**
 * 
 * @export
 * @interface PasswordResetModel
 */
export interface PasswordResetModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequestModel
 */
export interface PasswordResetRequestModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequestModel
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface SalaryPerJobRoleCollectionModel
 */
export interface SalaryPerJobRoleCollectionModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof SalaryPerJobRoleCollectionModel
     */
    categories?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalaryPerJobRoleCollectionModel
     */
    data?: Array<number> | null;
    /**
     * 
     * @type {Array<SalaryPerJobRoleModel>}
     * @memberof SalaryPerJobRoleCollectionModel
     */
    salaryPerJobRoleModels?: Array<SalaryPerJobRoleModel> | null;
}
/**
 * 
 * @export
 * @interface SalaryPerJobRoleModel
 */
export interface SalaryPerJobRoleModel {
    /**
     * 
     * @type {string}
     * @memberof SalaryPerJobRoleModel
     */
    jobRoleName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SalaryPerJobRoleModel
     */
    averageSalary?: number;
}
/**
 * 
 * @export
 * @interface SalaryPerNationalityCollectionModel
 */
export interface SalaryPerNationalityCollectionModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof SalaryPerNationalityCollectionModel
     */
    categories?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalaryPerNationalityCollectionModel
     */
    data?: Array<number> | null;
    /**
     * 
     * @type {Array<SalaryPerNationalityModel>}
     * @memberof SalaryPerNationalityCollectionModel
     */
    salaryPerNationalityModels?: Array<SalaryPerNationalityModel> | null;
}
/**
 * 
 * @export
 * @interface SalaryPerNationalityModel
 */
export interface SalaryPerNationalityModel {
    /**
     * 
     * @type {string}
     * @memberof SalaryPerNationalityModel
     */
    nationality?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SalaryPerNationalityModel
     */
    averageSalary?: number;
}
/**
 * 
 * @export
 * @interface ServiceDocumentModel
 */
export interface ServiceDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentModel
     */
    serviceId?: string;
    /**
     * 
     * @type {any}
     * @memberof ServiceDocumentModel
     */
    document?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentModel
     */
    serviceDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface ServiceDocumentUpdateModel
 */
export interface ServiceDocumentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceDocumentUpdateModel
     */
    filename: string;
}
/**
 * 
 * @export
 * @interface ServiceModel
 */
export interface ServiceModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    telephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    mainPointOfContact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    serviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceModel
     */
    countryName?: string | null;
}
/**
 * 
 * @export
 * @interface ServiceNoteEvidenceModel
 */
export interface ServiceNoteEvidenceModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteEvidenceModel
     */
    serviceNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteEvidenceModel
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteEvidenceModel
     */
    serviceNoteEvidenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteEvidenceModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteEvidenceModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface ServiceNoteModel
 */
export interface ServiceNoteModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    note?: string | null;
    /**
     * 
     * @type {Array<ServiceNoteEvidenceModel>}
     * @memberof ServiceNoteModel
     */
    serviceNoteEvidence?: Array<ServiceNoteEvidenceModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    serviceId?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ServiceNoteModel
     */
    evidence?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    serviceNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface ServiceNoteUpdateModel
 */
export interface ServiceNoteUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteUpdateModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteUpdateModel
     */
    note?: string | null;
    /**
     * 
     * @type {Array<ServiceNoteEvidenceModel>}
     * @memberof ServiceNoteUpdateModel
     */
    serviceNoteEvidence?: Array<ServiceNoteEvidenceModel> | null;
}
/**
 * 
 * @export
 * @interface ServiceUpsertModel
 */
export interface ServiceUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    telephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUpsertModel
     */
    mainPointOfContact?: string | null;
}
/**
 * 
 * @export
 * @interface StatementChargeModel
 */
export interface StatementChargeModel {
    /**
     * 
     * @type {string}
     * @memberof StatementChargeModel
     */
    statementId?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementChargeModel
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof StatementChargeModel
     */
    quantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatementChargeModel
     */
    unitAmount?: number | null;
    /**
     * 
     * @type {StatementChargeRecurrenceType}
     * @memberof StatementChargeModel
     */
    recurrenceType?: StatementChargeRecurrenceType;
    /**
     * 
     * @type {string}
     * @memberof StatementChargeModel
     */
    statementChargeId?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementChargeModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StatementChargeRecurrenceType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface StatementChargeUpsertModel
 */
export interface StatementChargeUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof StatementChargeUpsertModel
     */
    statementId?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementChargeUpsertModel
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof StatementChargeUpsertModel
     */
    quantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatementChargeUpsertModel
     */
    unitAmount?: number | null;
    /**
     * 
     * @type {StatementChargeRecurrenceType}
     * @memberof StatementChargeUpsertModel
     */
    recurrenceType?: StatementChargeRecurrenceType;
}
/**
 * 
 * @export
 * @interface StatementModel
 */
export interface StatementModel {
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    statementId?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {Array<StatementChargeModel>}
     * @memberof StatementModel
     */
    charges?: Array<StatementChargeModel> | null;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface StringStringConstantModel
 */
export interface StringStringConstantModel {
    /**
     * 
     * @type {string}
     * @memberof StringStringConstantModel
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringStringConstantModel
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface TokenModel
 */
export interface TokenModel {
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    nextOfKin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TokenModel
     */
    isStaff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    nextOfKinTelephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    employmentStartDate?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TokenModel
     */
    agencyCreateIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TokenModel
     */
    isAccessToApp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof TokenModel
     */
    loginAttempts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TokenModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TokenModel
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    roleDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    agencyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    modifiedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {Array<UserAgencyModel>}
     * @memberof TokenModel
     */
    userAgencies?: Array<UserAgencyModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TokenModel
     */
    agencyIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    agencyNames?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    accessTokenExpires?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    refreshToken?: string | null;
}
/**
 * 
 * @export
 * @interface UserAdminUpdateModel
 */
export interface UserAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    nextOfKin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    isStaff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    nextOfKinTelephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    employmentStartDate?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminUpdateModel
     */
    agencyCreateIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    isAccessToApp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof UserAdminUpdateModel
     */
    loginAttempts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @interface UserAgencyModel
 */
export interface UserAgencyModel {
    /**
     * 
     * @type {string}
     * @memberof UserAgencyModel
     */
    userAgencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgencyModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgencyModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAgencyModel
     */
    agencyName?: string | null;
}
/**
 * 
 * @export
 * @interface UserCreateModel
 */
export interface UserCreateModel {
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    nextOfKin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateModel
     */
    isStaff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    nextOfKinTelephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    employmentStartDate?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateModel
     */
    agencyCreateIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateModel
     */
    isAccessToApp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateModel
     */
    loginAttempts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateModel
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    crewMemberId?: string | null;
}
/**
 * 
 * @export
 * @interface UserDocumentModel
 */
export interface UserDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    userId?: string;
    /**
     * 
     * @type {any}
     * @memberof UserDocumentModel
     */
    document?: any | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    userDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    userFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    userLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    userFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface UserDocumentUpdateModel
 */
export interface UserDocumentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserDocumentUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentUpdateModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof UserDocumentUpdateModel
     */
    documentNumber?: string | null;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    nextOfKin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    isStaff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    nextOfKinTelephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    employmentStartDate?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModel
     */
    agencyCreateIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    isAccessToApp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    loginAttempts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    roleDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    agencyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    modifiedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {Array<UserAgencyModel>}
     * @memberof UserModel
     */
    userAgencies?: Array<UserAgencyModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModel
     */
    agencyIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    agencyNames?: string | null;
}
/**
 * 
 * @export
 * @interface UserNoteEvidenceModel
 */
export interface UserNoteEvidenceModel {
    /**
     * 
     * @type {string}
     * @memberof UserNoteEvidenceModel
     */
    crewMemberNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNoteEvidenceModel
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteEvidenceModel
     */
    userNoteEvidenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNoteEvidenceModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNoteEvidenceModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface UserNoteModel
 */
export interface UserNoteModel {
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    note?: string | null;
    /**
     * 
     * @type {Array<UserNoteEvidenceModel>}
     * @memberof UserNoteModel
     */
    userNoteEvidence?: Array<UserNoteEvidenceModel> | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    userId?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof UserNoteModel
     */
    evidence?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    userNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    userFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    userLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    userFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface UserNoteUpdateModel
 */
export interface UserNoteUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserNoteUpdateModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNoteUpdateModel
     */
    note?: string | null;
    /**
     * 
     * @type {Array<UserNoteEvidenceModel>}
     * @memberof UserNoteUpdateModel
     */
    userNoteEvidence?: Array<UserNoteEvidenceModel> | null;
}
/**
 * 
 * @export
 * @interface UserUpdateModel
 */
export interface UserUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    nextOfKin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateModel
     */
    isStaff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    nextOfKinTelephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    employmentStartDate?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateModel
     */
    agencyCreateIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateModel
     */
    isAccessToApp?: boolean;
}
/**
 * 
 * @export
 * @interface VesselDocumentModel
 */
export interface VesselDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    vesselId?: string;
    /**
     * 
     * @type {any}
     * @memberof VesselDocumentModel
     */
    document?: any | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    vesselDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    vesselName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    vesselRegistrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VesselDocumentModel
     */
    archived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselDocumentModel
     */
    adminViewOnly?: boolean;
}
/**
 * 
 * @export
 * @interface VesselDocumentUpdateModel
 */
export interface VesselDocumentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentUpdateModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentUpdateModel
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentUpdateModel
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselDocumentUpdateModel
     */
    endDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselDocumentUpdateModel
     */
    adminViewOnly?: boolean;
}
/**
 * 
 * @export
 * @interface VesselEnquiryCreateModel
 */
export interface VesselEnquiryCreateModel {
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    vesselId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryCreateModel
     */
    monthlyFeeGbp?: number;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryCreateModel
     */
    yearlyFee?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryCreateModel
     */
    isBritishPounds?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    nationalityPreferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryCreateModel
     */
    closed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    portOfJoining?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    airportOfEntry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    choiceOfLawJurisdiction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    billingEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryCreateModel
     */
    invoiceEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryCreateModel
     */
    documentsSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryCreateModel
     */
    documentsReceived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryCreateModel
     */
    documentsUploaded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryCreateModel
     */
    crewMemberReadyToGo?: boolean;
    /**
     * 
     * @type {ContractType}
     * @memberof VesselEnquiryCreateModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {Array<CrewMemberVesselEnquiryCreate>}
     * @memberof VesselEnquiryCreateModel
     */
    crewMemberVesselEnquiries?: Array<CrewMemberVesselEnquiryCreate> | null;
    /**
     * 
     * @type {Array<CertificateTypeVesselEnquiryCreateModel>}
     * @memberof VesselEnquiryCreateModel
     */
    requiredCertificates?: Array<CertificateTypeVesselEnquiryCreateModel> | null;
    /**
     * 
     * @type {Array<Array<CrewMemberVesselEnquiryCreate>>}
     * @memberof VesselEnquiryCreateModel
     */
    crewMembersByJobRole?: Array<Array<CrewMemberVesselEnquiryCreate>> | null;
}
/**
 * 
 * @export
 * @interface VesselEnquiryDocumentModel
 */
export interface VesselEnquiryDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentModel
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {any}
     * @memberof VesselEnquiryDocumentModel
     */
    document?: any | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentModel
     */
    vesselEnquiryDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface VesselEnquiryDocumentUpdateModel
 */
export interface VesselEnquiryDocumentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentUpdateModel
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryDocumentUpdateModel
     */
    filename: string;
}
/**
 * 
 * @export
 * @interface VesselEnquiryModel
 */
export interface VesselEnquiryModel {
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryModel
     */
    monthlyFeeGbp?: number;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryModel
     */
    yearlyFee?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    isBritishPounds?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    nationalityPreferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    closed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    portOfJoining?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    airportOfEntry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    choiceOfLawJurisdiction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    billingEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    invoiceEmail?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof VesselEnquiryModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {Array<CrewMemberVesselEnquiryCreate>}
     * @memberof VesselEnquiryModel
     */
    crewMemberVesselEnquiries?: Array<CrewMemberVesselEnquiryCreate> | null;
    /**
     * 
     * @type {Array<CertificateTypeVesselEnquiryCreateModel>}
     * @memberof VesselEnquiryModel
     */
    requiredCertificates?: Array<CertificateTypeVesselEnquiryCreateModel> | null;
    /**
     * 
     * @type {Array<Array<CrewMemberVesselEnquiryCreate>>}
     * @memberof VesselEnquiryModel
     */
    crewMembersByJobRole?: Array<Array<CrewMemberVesselEnquiryCreate>> | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselEnquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselRegistrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselContactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselRss?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryModel
     */
    vesselTonnage?: number;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    vesselContactPostalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    nationalityPreferenceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    lastInvoicedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    statusDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    nationalityPreferenceNationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {Array<CrewMemberModel>}
     * @memberof VesselEnquiryModel
     */
    assignees?: Array<CrewMemberModel> | null;
    /**
     * 
     * @type {Array<JobRoleModel>}
     * @memberof VesselEnquiryModel
     */
    vesselEnquiryJobRoles?: Array<JobRoleModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    documentsSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    documentsReceived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    documentsUploaded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryModel
     */
    crewMemberReadyToGo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    assigneeNames?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryModel
     */
    jobRoles?: string | null;
}
/**
 * 
 * @export
 * @interface VesselEnquiryUpdateModel
 */
export interface VesselEnquiryUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    vesselId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryUpdateModel
     */
    monthlyFeeGbp?: number;
    /**
     * 
     * @type {number}
     * @memberof VesselEnquiryUpdateModel
     */
    yearlyFee?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryUpdateModel
     */
    isBritishPounds?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    nationalityPreferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryUpdateModel
     */
    closed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    portOfJoining?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    airportOfEntry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    choiceOfLawJurisdiction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    billingEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEnquiryUpdateModel
     */
    invoiceEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryUpdateModel
     */
    documentsSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryUpdateModel
     */
    documentsReceived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryUpdateModel
     */
    documentsUploaded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselEnquiryUpdateModel
     */
    crewMemberReadyToGo?: boolean;
}
/**
 * 
 * @export
 * @interface VesselModel
 */
export interface VesselModel {
    /**
     * 
     * @type {ContractType}
     * @memberof VesselModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    vesselTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    registrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    rss: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    contactPostalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    invoiceEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VesselModel
     */
    tonnage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VesselModel
     */
    doNotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselModel
     */
    sponsoredVessel?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    sponsorshipLicenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    imoNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    vesselId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    agencyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    vesselTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    vesselPictureId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    currentContractStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselModel
     */
    currentContractEndDate?: string | null;
    /**
     * 
     * @type {Array<VesselNoteModel>}
     * @memberof VesselModel
     */
    vesselNotes?: Array<VesselNoteModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VesselModel
     */
    contactVessel?: boolean;
}
/**
 * 
 * @export
 * @interface VesselNoteEvidenceModel
 */
export interface VesselNoteEvidenceModel {
    /**
     * 
     * @type {string}
     * @memberof VesselNoteEvidenceModel
     */
    vesselNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteEvidenceModel
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteEvidenceModel
     */
    vesselNoteEvidenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteEvidenceModel
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteEvidenceModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface VesselNoteModel
 */
export interface VesselNoteModel {
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    note?: string | null;
    /**
     * 
     * @type {Array<VesselNoteEvidenceModel>}
     * @memberof VesselNoteModel
     */
    vesselNoteEvidence?: Array<VesselNoteEvidenceModel> | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    vesselId?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof VesselNoteModel
     */
    evidence?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    vesselNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    vesselName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface VesselNoteUpdateModel
 */
export interface VesselNoteUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof VesselNoteUpdateModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselNoteUpdateModel
     */
    note?: string | null;
    /**
     * 
     * @type {Array<VesselNoteEvidenceModel>}
     * @memberof VesselNoteUpdateModel
     */
    vesselNoteEvidence?: Array<VesselNoteEvidenceModel> | null;
}
/**
 * 
 * @export
 * @interface VesselTypeModel
 */
export interface VesselTypeModel {
    /**
     * 
     * @type {string}
     * @memberof VesselTypeModel
     */
    name: string;
    /**
     * 
     * @type {ContractType}
     * @memberof VesselTypeModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof VesselTypeModel
     */
    vesselTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof VesselTypeModel
     */
    createdByUserFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselTypeModel
     */
    createdByUserLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselTypeModel
     */
    createdByUserFullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselTypeModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface VesselTypeUpsertModel
 */
export interface VesselTypeUpsertModel {
    /**
     * 
     * @type {string}
     * @memberof VesselTypeUpsertModel
     */
    name: string;
    /**
     * 
     * @type {ContractType}
     * @memberof VesselTypeUpsertModel
     */
    discriminator?: ContractType;
}
/**
 * 
 * @export
 * @interface VesselUpsertModel
 */
export interface VesselUpsertModel {
    /**
     * 
     * @type {ContractType}
     * @memberof VesselUpsertModel
     */
    discriminator?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    agencyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    vesselTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    registrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    rss: string;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    contactPostalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    invoiceEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VesselUpsertModel
     */
    tonnage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VesselUpsertModel
     */
    doNotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VesselUpsertModel
     */
    sponsoredVessel?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    sponsorshipLicenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselUpsertModel
     */
    imoNumber?: string | null;
}

/**
 * AgencyApi - axios parameter creator
 * @export
 */
export const AgencyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyIdDelete: async (agencyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyId' is not null or undefined
            assertParamExists('apiAgencyAgencyIdDelete', 'agencyId', agencyId)
            const localVarPath = `/api/agency/{agencyId}`
                .replace(`{${"agencyId"}}`, encodeURIComponent(String(agencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyIdGet: async (agencyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyId' is not null or undefined
            assertParamExists('apiAgencyAgencyIdGet', 'agencyId', agencyId)
            const localVarPath = `/api/agency/{agencyId}`
                .replace(`{${"agencyId"}}`, encodeURIComponent(String(agencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {AgencyUpsertModel} [agencyUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyIdPut: async (agencyId: string, agencyUpsertModel?: AgencyUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyId' is not null or undefined
            assertParamExists('apiAgencyAgencyIdPut', 'agencyId', agencyId)
            const localVarPath = `/api/agency/{agencyId}`
                .replace(`{${"agencyId"}}`, encodeURIComponent(String(agencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agencyUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyadminUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAgencyAgencyadminUserIdGet', 'userId', userId)
            const localVarPath = `/api/agency/agencyadmin/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiAgencyDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/agency/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgencyUpsertModel} [agencyUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyPost: async (agencyUpsertModel?: AgencyUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agencyUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyApi - functional programming interface
 * @export
 */
export const AgencyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgencyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyAgencyIdDelete(agencyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyAgencyIdDelete(agencyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyAgencyIdGet(agencyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyAgencyIdGet(agencyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {AgencyUpsertModel} [agencyUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyAgencyIdPut(agencyId: string, agencyUpsertModel?: AgencyUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyAgencyIdPut(agencyId, agencyUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyAgencyadminUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgencyModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyAgencyadminUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgencyModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgencyModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AgencyUpsertModel} [agencyUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencyPost(agencyUpsertModel?: AgencyUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencyPost(agencyUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgencyApi - factory interface
 * @export
 */
export const AgencyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgencyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyIdDelete(agencyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgencyAgencyIdDelete(agencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyIdGet(agencyId: string, options?: any): AxiosPromise<AgencyModel> {
            return localVarFp.apiAgencyAgencyIdGet(agencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {AgencyUpsertModel} [agencyUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyIdPut(agencyId: string, agencyUpsertModel?: AgencyUpsertModel, options?: any): AxiosPromise<AgencyModel> {
            return localVarFp.apiAgencyAgencyIdPut(agencyId, agencyUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyAgencyadminUserIdGet(userId: string, options?: any): AxiosPromise<Array<AgencyModel>> {
            return localVarFp.apiAgencyAgencyadminUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<AgencyModel>> {
            return localVarFp.apiAgencyDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyGet(options?: any): AxiosPromise<Array<AgencyModel>> {
            return localVarFp.apiAgencyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgencyUpsertModel} [agencyUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencyPost(agencyUpsertModel?: AgencyUpsertModel, options?: any): AxiosPromise<AgencyModel> {
            return localVarFp.apiAgencyPost(agencyUpsertModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgencyApi - object-oriented interface
 * @export
 * @class AgencyApi
 * @extends {BaseAPI}
 */
export class AgencyApi extends BaseAPI {
    /**
     * 
     * @param {string} agencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyAgencyIdDelete(agencyId: string, options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyAgencyIdDelete(agencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyAgencyIdGet(agencyId: string, options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyAgencyIdGet(agencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agencyId 
     * @param {AgencyUpsertModel} [agencyUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyAgencyIdPut(agencyId: string, agencyUpsertModel?: AgencyUpsertModel, options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyAgencyIdPut(agencyId, agencyUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyAgencyadminUserIdGet(userId: string, options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyAgencyadminUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyDiscriminatorGet(discriminator: ContractType, options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyGet(options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AgencyUpsertModel} [agencyUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public apiAgencyPost(agencyUpsertModel?: AgencyUpsertModel, options?: any) {
        return AgencyApiFp(this.configuration).apiAgencyPost(agencyUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgencyDocumentApi - axios parameter creator
 * @export
 */
export const AgencyDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyAgencyIdGet: async (agencyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyId' is not null or undefined
            assertParamExists('apiAgencydocumentAgencyAgencyIdGet', 'agencyId', agencyId)
            const localVarPath = `/api/agencydocument/agency/{agencyId}`
                .replace(`{${"agencyId"}}`, encodeURIComponent(String(agencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyDocumentIdDelete: async (agencyDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyDocumentId' is not null or undefined
            assertParamExists('apiAgencydocumentAgencyDocumentIdDelete', 'agencyDocumentId', agencyDocumentId)
            const localVarPath = `/api/agencydocument/{agencyDocumentId}`
                .replace(`{${"agencyDocumentId"}}`, encodeURIComponent(String(agencyDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyDocumentIdGet: async (agencyDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyDocumentId' is not null or undefined
            assertParamExists('apiAgencydocumentAgencyDocumentIdGet', 'agencyDocumentId', agencyDocumentId)
            const localVarPath = `/api/agencydocument/{agencyDocumentId}`
                .replace(`{${"agencyDocumentId"}}`, encodeURIComponent(String(agencyDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {AgencyDocumentUpdateModel} [agencyDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyDocumentIdPut: async (agencyDocumentId: string, agencyDocumentUpdateModel?: AgencyDocumentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyDocumentId' is not null or undefined
            assertParamExists('apiAgencydocumentAgencyDocumentIdPut', 'agencyDocumentId', agencyDocumentId)
            const localVarPath = `/api/agencydocument/{agencyDocumentId}`
                .replace(`{${"agencyDocumentId"}}`, encodeURIComponent(String(agencyDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agencyDocumentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [agencyId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentPost: async (filename: string, agencyId?: string, document?: any, reference?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiAgencydocumentPost', 'filename', filename)
            const localVarPath = `/api/agencydocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (agencyId !== undefined) { 
                localVarFormParams.append('AgencyId', new Blob([JSON.stringify(agencyId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyDocumentApi - functional programming interface
 * @export
 */
export const AgencyDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgencyDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencydocumentAgencyAgencyIdGet(agencyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgencyDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencydocumentAgencyAgencyIdGet(agencyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencydocumentAgencyDocumentIdDelete(agencyDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencydocumentAgencyDocumentIdDelete(agencyDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencydocumentAgencyDocumentIdGet(agencyDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencydocumentAgencyDocumentIdGet(agencyDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {AgencyDocumentUpdateModel} [agencyDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencydocumentAgencyDocumentIdPut(agencyDocumentId: string, agencyDocumentUpdateModel?: AgencyDocumentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencydocumentAgencyDocumentIdPut(agencyDocumentId, agencyDocumentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [agencyId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAgencydocumentPost(filename: string, agencyId?: string, document?: any, reference?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAgencydocumentPost(filename, agencyId, document, reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgencyDocumentApi - factory interface
 * @export
 */
export const AgencyDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgencyDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyAgencyIdGet(agencyId: string, options?: any): AxiosPromise<Array<AgencyDocumentModel>> {
            return localVarFp.apiAgencydocumentAgencyAgencyIdGet(agencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyDocumentIdDelete(agencyDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAgencydocumentAgencyDocumentIdDelete(agencyDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyDocumentIdGet(agencyDocumentId: string, options?: any): AxiosPromise<AgencyDocumentModel> {
            return localVarFp.apiAgencydocumentAgencyDocumentIdGet(agencyDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agencyDocumentId 
         * @param {AgencyDocumentUpdateModel} [agencyDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentAgencyDocumentIdPut(agencyDocumentId: string, agencyDocumentUpdateModel?: AgencyDocumentUpdateModel, options?: any): AxiosPromise<AgencyDocumentModel> {
            return localVarFp.apiAgencydocumentAgencyDocumentIdPut(agencyDocumentId, agencyDocumentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [agencyId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAgencydocumentPost(filename: string, agencyId?: string, document?: any, reference?: string, options?: any): AxiosPromise<AgencyDocumentModel> {
            return localVarFp.apiAgencydocumentPost(filename, agencyId, document, reference, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgencyDocumentApi - object-oriented interface
 * @export
 * @class AgencyDocumentApi
 * @extends {BaseAPI}
 */
export class AgencyDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} agencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyDocumentApi
     */
    public apiAgencydocumentAgencyAgencyIdGet(agencyId: string, options?: any) {
        return AgencyDocumentApiFp(this.configuration).apiAgencydocumentAgencyAgencyIdGet(agencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agencyDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyDocumentApi
     */
    public apiAgencydocumentAgencyDocumentIdDelete(agencyDocumentId: string, options?: any) {
        return AgencyDocumentApiFp(this.configuration).apiAgencydocumentAgencyDocumentIdDelete(agencyDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agencyDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyDocumentApi
     */
    public apiAgencydocumentAgencyDocumentIdGet(agencyDocumentId: string, options?: any) {
        return AgencyDocumentApiFp(this.configuration).apiAgencydocumentAgencyDocumentIdGet(agencyDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agencyDocumentId 
     * @param {AgencyDocumentUpdateModel} [agencyDocumentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyDocumentApi
     */
    public apiAgencydocumentAgencyDocumentIdPut(agencyDocumentId: string, agencyDocumentUpdateModel?: AgencyDocumentUpdateModel, options?: any) {
        return AgencyDocumentApiFp(this.configuration).apiAgencydocumentAgencyDocumentIdPut(agencyDocumentId, agencyDocumentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [agencyId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyDocumentApi
     */
    public apiAgencydocumentPost(filename: string, agencyId?: string, document?: any, reference?: string, options?: any) {
        return AgencyDocumentApiFp(this.configuration).apiAgencydocumentPost(filename, agencyId, document, reference, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TokenModel} [tokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshTokenPost: async (tokenModel?: TokenModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetPasswordIdPost: async (id: string, passwordResetModel?: PasswordResetModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAuthResetPasswordIdPost', 'id', id)
            const localVarPath = `/api/auth/reset-password/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetPasswordPost: async (passwordResetModel?: PasswordResetModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSendPasswordResetPost: async (passwordResetRequestModel?: PasswordResetRequestModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/send-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorisationModel} [authorisationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenPost: async (authorisationModel?: AuthorisationModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorisationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TokenModel} [tokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthRefreshTokenPost(tokenModel?: TokenModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRefreshTokenPost(tokenModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthResetPasswordIdPost(id: string, passwordResetModel?: PasswordResetModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetPasswordIdPost(id, passwordResetModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthResetPasswordPost(passwordResetModel?: PasswordResetModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetPasswordPost(passwordResetModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSendPasswordResetPost(passwordResetRequestModel?: PasswordResetRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSendPasswordResetPost(passwordResetRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorisationModel} [authorisationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthTokenPost(authorisationModel?: AuthorisationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthTokenPost(authorisationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {TokenModel} [tokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshTokenPost(tokenModel?: TokenModel, options?: any): AxiosPromise<TokenModel> {
            return localVarFp.apiAuthRefreshTokenPost(tokenModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetPasswordIdPost(id: string, passwordResetModel?: PasswordResetModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthResetPasswordIdPost(id, passwordResetModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetPasswordPost(passwordResetModel?: PasswordResetModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthResetPasswordPost(passwordResetModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSendPasswordResetPost(passwordResetRequestModel?: PasswordResetRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthSendPasswordResetPost(passwordResetRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorisationModel} [authorisationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenPost(authorisationModel?: AuthorisationModel, options?: any): AxiosPromise<TokenModel> {
            return localVarFp.apiAuthTokenPost(authorisationModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {TokenModel} [tokenModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthRefreshTokenPost(tokenModel?: TokenModel, options?: any) {
        return AuthApiFp(this.configuration).apiAuthRefreshTokenPost(tokenModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PasswordResetModel} [passwordResetModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthResetPasswordIdPost(id: string, passwordResetModel?: PasswordResetModel, options?: any) {
        return AuthApiFp(this.configuration).apiAuthResetPasswordIdPost(id, passwordResetModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetModel} [passwordResetModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthResetPasswordPost(passwordResetModel?: PasswordResetModel, options?: any) {
        return AuthApiFp(this.configuration).apiAuthResetPasswordPost(passwordResetModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSendPasswordResetPost(passwordResetRequestModel?: PasswordResetRequestModel, options?: any) {
        return AuthApiFp(this.configuration).apiAuthSendPasswordResetPost(passwordResetRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorisationModel} [authorisationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthTokenPost(authorisationModel?: AuthorisationModel, options?: any) {
        return AuthApiFp(this.configuration).apiAuthTokenPost(authorisationModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CertificateTypeApi - axios parameter creator
 * @export
 */
export const CertificateTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeCertificateTypeIdDelete: async (certificateTypeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateTypeId' is not null or undefined
            assertParamExists('apiCertificatetypeCertificateTypeIdDelete', 'certificateTypeId', certificateTypeId)
            const localVarPath = `/api/certificatetype/{certificateTypeId}`
                .replace(`{${"certificateTypeId"}}`, encodeURIComponent(String(certificateTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeCertificateTypeIdGet: async (certificateTypeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateTypeId' is not null or undefined
            assertParamExists('apiCertificatetypeCertificateTypeIdGet', 'certificateTypeId', certificateTypeId)
            const localVarPath = `/api/certificatetype/{certificateTypeId}`
                .replace(`{${"certificateTypeId"}}`, encodeURIComponent(String(certificateTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeCertificateTypeIdPut: async (certificateTypeId: string, certificateTypeUpsertModel?: CertificateTypeUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateTypeId' is not null or undefined
            assertParamExists('apiCertificatetypeCertificateTypeIdPut', 'certificateTypeId', certificateTypeId)
            const localVarPath = `/api/certificatetype/{certificateTypeId}`
                .replace(`{${"certificateTypeId"}}`, encodeURIComponent(String(certificateTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certificateTypeUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCertificatetypeDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/certificatetype/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificatetype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypePost: async (certificateTypeUpsertModel?: CertificateTypeUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificatetype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certificateTypeUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificateTypeApi - functional programming interface
 * @export
 */
export const CertificateTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificateTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypeCertificateTypeIdDelete(certificateTypeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypeCertificateTypeIdDelete(certificateTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypeCertificateTypeIdGet(certificateTypeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypeCertificateTypeIdGet(certificateTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypeCertificateTypeIdPut(certificateTypeId: string, certificateTypeUpsertModel?: CertificateTypeUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypeCertificateTypeIdPut(certificateTypeId, certificateTypeUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypeDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypeDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypePost(certificateTypeUpsertModel?: CertificateTypeUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypePost(certificateTypeUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificateTypeApi - factory interface
 * @export
 */
export const CertificateTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificateTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeCertificateTypeIdDelete(certificateTypeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertificatetypeCertificateTypeIdDelete(certificateTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeCertificateTypeIdGet(certificateTypeId: string, options?: any): AxiosPromise<CertificateTypeModel> {
            return localVarFp.apiCertificatetypeCertificateTypeIdGet(certificateTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} certificateTypeId 
         * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeCertificateTypeIdPut(certificateTypeId: string, certificateTypeUpsertModel?: CertificateTypeUpsertModel, options?: any): AxiosPromise<CertificateTypeModel> {
            return localVarFp.apiCertificatetypeCertificateTypeIdPut(certificateTypeId, certificateTypeUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<CertificateTypeModel>> {
            return localVarFp.apiCertificatetypeDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypeGet(options?: any): AxiosPromise<Array<CertificateTypeModel>> {
            return localVarFp.apiCertificatetypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypePost(certificateTypeUpsertModel?: CertificateTypeUpsertModel, options?: any): AxiosPromise<CertificateTypeModel> {
            return localVarFp.apiCertificatetypePost(certificateTypeUpsertModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificateTypeApi - object-oriented interface
 * @export
 * @class CertificateTypeApi
 * @extends {BaseAPI}
 */
export class CertificateTypeApi extends BaseAPI {
    /**
     * 
     * @param {string} certificateTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeApi
     */
    public apiCertificatetypeCertificateTypeIdDelete(certificateTypeId: string, options?: any) {
        return CertificateTypeApiFp(this.configuration).apiCertificatetypeCertificateTypeIdDelete(certificateTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} certificateTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeApi
     */
    public apiCertificatetypeCertificateTypeIdGet(certificateTypeId: string, options?: any) {
        return CertificateTypeApiFp(this.configuration).apiCertificatetypeCertificateTypeIdGet(certificateTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} certificateTypeId 
     * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeApi
     */
    public apiCertificatetypeCertificateTypeIdPut(certificateTypeId: string, certificateTypeUpsertModel?: CertificateTypeUpsertModel, options?: any) {
        return CertificateTypeApiFp(this.configuration).apiCertificatetypeCertificateTypeIdPut(certificateTypeId, certificateTypeUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeApi
     */
    public apiCertificatetypeDiscriminatorGet(discriminator: ContractType, options?: any) {
        return CertificateTypeApiFp(this.configuration).apiCertificatetypeDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeApi
     */
    public apiCertificatetypeGet(options?: any) {
        return CertificateTypeApiFp(this.configuration).apiCertificatetypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertificateTypeUpsertModel} [certificateTypeUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeApi
     */
    public apiCertificatetypePost(certificateTypeUpsertModel?: CertificateTypeUpsertModel, options?: any) {
        return CertificateTypeApiFp(this.configuration).apiCertificatetypePost(certificateTypeUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CertificateTypeVesselEnquiryApi - axios parameter creator
 * @export
 */
export const CertificateTypeVesselEnquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} certificateTypeVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete: async (certificateTypeVesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateTypeVesselEnquiryId' is not null or undefined
            assertParamExists('apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete', 'certificateTypeVesselEnquiryId', certificateTypeVesselEnquiryId)
            const localVarPath = `/api/certificatetypevesselenquiry/{certificateTypeVesselEnquiryId}`
                .replace(`{${"certificateTypeVesselEnquiryId"}}`, encodeURIComponent(String(certificateTypeVesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} certificateTypeVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet: async (certificateTypeVesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateTypeVesselEnquiryId' is not null or undefined
            assertParamExists('apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet', 'certificateTypeVesselEnquiryId', certificateTypeVesselEnquiryId)
            const localVarPath = `/api/certificatetypevesselenquiry/{certificateTypeVesselEnquiryId}`
                .replace(`{${"certificateTypeVesselEnquiryId"}}`, encodeURIComponent(String(certificateTypeVesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/certificatetypevesselenquiry/enquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificatetypevesselenquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CertificateTypeVesselEnquiryCreateModel} [certificateTypeVesselEnquiryCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryPost: async (certificateTypeVesselEnquiryCreateModel?: CertificateTypeVesselEnquiryCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificatetypevesselenquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certificateTypeVesselEnquiryCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificateTypeVesselEnquiryApi - functional programming interface
 * @export
 */
export const CertificateTypeVesselEnquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificateTypeVesselEnquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} certificateTypeVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(certificateTypeVesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(certificateTypeVesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} certificateTypeVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet(certificateTypeVesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateTypeVesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet(certificateTypeVesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateTypeVesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypevesselenquiryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateTypeVesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypevesselenquiryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CertificateTypeVesselEnquiryCreateModel} [certificateTypeVesselEnquiryCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatetypevesselenquiryPost(certificateTypeVesselEnquiryCreateModel?: CertificateTypeVesselEnquiryCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateTypeVesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatetypevesselenquiryPost(certificateTypeVesselEnquiryCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificateTypeVesselEnquiryApi - factory interface
 * @export
 */
export const CertificateTypeVesselEnquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificateTypeVesselEnquiryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} certificateTypeVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(certificateTypeVesselEnquiryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(certificateTypeVesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} certificateTypeVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet(certificateTypeVesselEnquiryId: string, options?: any): AxiosPromise<CertificateTypeVesselEnquiryModel> {
            return localVarFp.apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet(certificateTypeVesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): AxiosPromise<Array<CertificateTypeVesselEnquiryModel>> {
            return localVarFp.apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryGet(options?: any): AxiosPromise<Array<CertificateTypeVesselEnquiryModel>> {
            return localVarFp.apiCertificatetypevesselenquiryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CertificateTypeVesselEnquiryCreateModel} [certificateTypeVesselEnquiryCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatetypevesselenquiryPost(certificateTypeVesselEnquiryCreateModel?: CertificateTypeVesselEnquiryCreateModel, options?: any): AxiosPromise<CertificateTypeVesselEnquiryModel> {
            return localVarFp.apiCertificatetypevesselenquiryPost(certificateTypeVesselEnquiryCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificateTypeVesselEnquiryApi - object-oriented interface
 * @export
 * @class CertificateTypeVesselEnquiryApi
 * @extends {BaseAPI}
 */
export class CertificateTypeVesselEnquiryApi extends BaseAPI {
    /**
     * 
     * @param {string} certificateTypeVesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeVesselEnquiryApi
     */
    public apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(certificateTypeVesselEnquiryId: string, options?: any) {
        return CertificateTypeVesselEnquiryApiFp(this.configuration).apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(certificateTypeVesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} certificateTypeVesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeVesselEnquiryApi
     */
    public apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet(certificateTypeVesselEnquiryId: string, options?: any) {
        return CertificateTypeVesselEnquiryApiFp(this.configuration).apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdGet(certificateTypeVesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeVesselEnquiryApi
     */
    public apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any) {
        return CertificateTypeVesselEnquiryApiFp(this.configuration).apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeVesselEnquiryApi
     */
    public apiCertificatetypevesselenquiryGet(options?: any) {
        return CertificateTypeVesselEnquiryApiFp(this.configuration).apiCertificatetypevesselenquiryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertificateTypeVesselEnquiryCreateModel} [certificateTypeVesselEnquiryCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateTypeVesselEnquiryApi
     */
    public apiCertificatetypevesselenquiryPost(certificateTypeVesselEnquiryCreateModel?: CertificateTypeVesselEnquiryCreateModel, options?: any) {
        return CertificateTypeVesselEnquiryApiFp(this.configuration).apiCertificatetypevesselenquiryPost(certificateTypeVesselEnquiryCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConstantApi - axios parameter creator
 * @export
 */
export const ConstantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantUserRoleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/constant/user-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConstantApi - functional programming interface
 * @export
 */
export const ConstantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConstantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantUserRoleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StringStringConstantModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantUserRoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConstantApi - factory interface
 * @export
 */
export const ConstantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConstantApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantUserRoleGet(options?: any): AxiosPromise<Array<StringStringConstantModel>> {
            return localVarFp.apiConstantUserRoleGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConstantApi - object-oriented interface
 * @export
 * @class ConstantApi
 * @extends {BaseAPI}
 */
export class ConstantApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantApi
     */
    public apiConstantUserRoleGet(options?: any) {
        return ConstantApiFp(this.configuration).apiConstantUserRoleGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountryCountryIdGet: async (countryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiCountryCountryIdGet', 'countryId', countryId)
            const localVarPath = `/api/country/{countryId}`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCountryCountryIdGet(countryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCountryCountryIdGet(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCountryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCountryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountryCountryIdGet(countryId: string, options?: any): AxiosPromise<CountryModel> {
            return localVarFp.apiCountryCountryIdGet(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountryGet(options?: any): AxiosPromise<Array<CountryModel>> {
            return localVarFp.apiCountryGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public apiCountryCountryIdGet(countryId: string, options?: any) {
        return CountryApiFp(this.configuration).apiCountryCountryIdGet(countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public apiCountryGet(options?: any) {
        return CountryApiFp(this.configuration).apiCountryGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrewMemberApi - axios parameter creator
 * @export
 */
export const CrewMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberActiveSkilledWorkerUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCrewmemberActiveSkilledWorkerUserIdGet', 'userId', userId)
            const localVarPath = `/api/crewmember/active/skilled-worker/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberActiveUserIdDiscriminatorGet: async (userId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCrewmemberActiveUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberActiveUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmember/active/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberActiveUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCrewmemberActiveUserIdGet', 'userId', userId)
            const localVarPath = `/api/crewmember/active/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberCrewMemberIdDelete: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberCrewMemberIdDelete', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmember/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmember/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberCrewMemberIdPut: async (crewMemberId: string, crewMemberUpsertModel?: CrewMemberUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberCrewMemberIdPut', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmember/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmember/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberDonotuseAgencyIdGet: async (agencyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyId' is not null or undefined
            assertParamExists('apiCrewmemberDonotuseAgencyIdGet', 'agencyId', agencyId)
            const localVarPath = `/api/crewmember/donotuse/{agencyId}`
                .replace(`{${"agencyId"}}`, encodeURIComponent(String(agencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberDonotuseGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/donotuse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberEmailEmailAddressGet: async (emailAddress: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiCrewmemberEmailEmailAddressGet', 'emailAddress', emailAddress)
            const localVarPath = `/api/crewmember/email/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportActiveGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/export-active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportActiveJobroleJobRoleIdGet: async (jobRoleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobRoleId' is not null or undefined
            assertParamExists('apiCrewmemberExportActiveJobroleJobRoleIdGet', 'jobRoleId', jobRoleId)
            const localVarPath = `/api/crewmember/export-active-jobrole/{jobRoleId}`
                .replace(`{${"jobRoleId"}}`, encodeURIComponent(String(jobRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportActiveNationalityCountryIdGet: async (countryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiCrewmemberExportActiveNationalityCountryIdGet', 'countryId', countryId)
            const localVarPath = `/api/crewmember/export-active-nationality/{countryId}`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportAvailableGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/export-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportAvailableJobroleJobRoleIdGet: async (jobRoleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobRoleId' is not null or undefined
            assertParamExists('apiCrewmemberExportAvailableJobroleJobRoleIdGet', 'jobRoleId', jobRoleId)
            const localVarPath = `/api/crewmember/export-available-jobrole/{jobRoleId}`
                .replace(`{${"jobRoleId"}}`, encodeURIComponent(String(jobRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportAvailableNationalityCountryIdGet: async (countryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiCrewmemberExportAvailableNationalityCountryIdGet', 'countryId', countryId)
            const localVarPath = `/api/crewmember/export-available-nationality/{countryId}`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportSkilledWorkersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/export-skilled-workers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberInCountryDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmember/in-country/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/in-country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryNationalityDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberInCountryNationalityDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmember/in-country/nationality/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryNationalitySkilledWorkersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/in-country/nationality/skilled-workers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountrySkilledWorkersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/in-country/skilled-workers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberOutCountryNationalityDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberOutCountryNationalityDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmember/out-country/nationality/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberOutCountryNationalitySkilledWorkersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/out-country/nationality/skilled-workers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPassportExpiryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember/passport/expiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPassportExpiryUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCrewmemberPassportExpiryUserIdGet', 'userId', userId)
            const localVarPath = `/api/crewmember/passport/expiry/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPhotoIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCrewmemberPhotoIdGet', 'id', id)
            const localVarPath = `/api/crewmember/photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {boolean} [adminViewOnly] 
         * @param {string} [documentNumber] 
         * @param {string} [expiryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPhotoPost: async (filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiCrewmemberPhotoPost', 'filename', filename)
            const localVarPath = `/api/crewmember/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (crewMemberId !== undefined) { 
                localVarFormParams.append('CrewMemberId', new Blob([JSON.stringify(crewMemberId)], { type: "application/json", }));
            }
    
            if (discriminator !== undefined) { 
                localVarFormParams.append('Discriminator', new Blob([JSON.stringify(discriminator)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (adminViewOnly !== undefined) { 
                localVarFormParams.append('AdminViewOnly', adminViewOnly as any);
            }
    
            if (documentNumber !== undefined) { 
                localVarFormParams.append('DocumentNumber', documentNumber as any);
            }
    
            if (expiryDate !== undefined) { 
                localVarFormParams.append('ExpiryDate', expiryDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPost: async (crewMemberUpsertModel?: CrewMemberUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrewMemberApi - functional programming interface
 * @export
 */
export const CrewMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrewMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberActiveSkilledWorkerUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberActiveSkilledWorkerUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberActiveUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberActiveUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberActiveUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberActiveUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberCrewMemberIdDelete(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberCrewMemberIdDelete(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberCrewMemberIdPut(crewMemberId: string, crewMemberUpsertModel?: CrewMemberUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberCrewMemberIdPut(crewMemberId, crewMemberUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberDonotuseAgencyIdGet(agencyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberDonotuseAgencyIdGet(agencyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberDonotuseGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberDonotuseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberEmailEmailAddressGet(emailAddress: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberEmailEmailAddressGet(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportActiveGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportActiveGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportActiveJobroleJobRoleIdGet(jobRoleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportActiveJobroleJobRoleIdGet(jobRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportActiveNationalityCountryIdGet(countryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportActiveNationalityCountryIdGet(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportAvailableGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportAvailableGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportAvailableJobroleJobRoleIdGet(jobRoleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportAvailableJobroleJobRoleIdGet(jobRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportAvailableNationalityCountryIdGet(countryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportAvailableNationalityCountryIdGet(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberExportSkilledWorkersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberExportSkilledWorkersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberInCountryDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInCountryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberInCountryDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberInCountryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInCountryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberInCountryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberInCountryNationalityDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InCountryPerNationalityCollectionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberInCountryNationalityDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberInCountryNationalitySkilledWorkersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InCountryPerNationalityCollectionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberInCountryNationalitySkilledWorkersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberInCountrySkilledWorkersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInCountryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberInCountrySkilledWorkersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberOutCountryNationalityDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutCountryPerNationalityCollectionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberOutCountryNationalityDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberOutCountryNationalitySkilledWorkersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutCountryPerNationalityCollectionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberOutCountryNationalitySkilledWorkersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberPassportExpiryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberPassportExpiryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberPassportExpiryUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberPassportExpiryUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberPhotoIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberPhotoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {boolean} [adminViewOnly] 
         * @param {string} [documentNumber] 
         * @param {string} [expiryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberPhotoPost(filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberPhotoPost(filename, crewMemberId, discriminator, document, reference, adminViewOnly, documentNumber, expiryDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberPost(crewMemberUpsertModel?: CrewMemberUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberPost(crewMemberUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrewMemberApi - factory interface
 * @export
 */
export const CrewMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrewMemberApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberActiveSkilledWorkerUserIdGet(userId: string, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberActiveSkilledWorkerUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberActiveUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberActiveUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberActiveUserIdGet(userId: string, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberActiveUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberCrewMemberIdDelete(crewMemberId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmemberCrewMemberIdDelete(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<CrewMemberModel> {
            return localVarFp.apiCrewmemberCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberCrewMemberIdPut(crewMemberId: string, crewMemberUpsertModel?: CrewMemberUpsertModel, options?: any): AxiosPromise<CrewMemberModel> {
            return localVarFp.apiCrewmemberCrewMemberIdPut(crewMemberId, crewMemberUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberDonotuseAgencyIdGet(agencyId: string, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberDonotuseAgencyIdGet(agencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberDonotuseGet(options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberDonotuseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberEmailEmailAddressGet(emailAddress: string, options?: any): AxiosPromise<CrewMemberModel> {
            return localVarFp.apiCrewmemberEmailEmailAddressGet(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportActiveGet(options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportActiveGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportActiveJobroleJobRoleIdGet(jobRoleId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportActiveJobroleJobRoleIdGet(jobRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportActiveNationalityCountryIdGet(countryId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportActiveNationalityCountryIdGet(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportAvailableGet(options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportAvailableGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportAvailableJobroleJobRoleIdGet(jobRoleId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportAvailableJobroleJobRoleIdGet(jobRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportAvailableNationalityCountryIdGet(countryId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportAvailableNationalityCountryIdGet(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberExportSkilledWorkersGet(options?: any): AxiosPromise<any> {
            return localVarFp.apiCrewmemberExportSkilledWorkersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberGet(options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<CrewMemberInCountryModel> {
            return localVarFp.apiCrewmemberInCountryDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryGet(options?: any): AxiosPromise<CrewMemberInCountryModel> {
            return localVarFp.apiCrewmemberInCountryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryNationalityDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<InCountryPerNationalityCollectionModel> {
            return localVarFp.apiCrewmemberInCountryNationalityDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountryNationalitySkilledWorkersGet(options?: any): AxiosPromise<InCountryPerNationalityCollectionModel> {
            return localVarFp.apiCrewmemberInCountryNationalitySkilledWorkersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberInCountrySkilledWorkersGet(options?: any): AxiosPromise<CrewMemberInCountryModel> {
            return localVarFp.apiCrewmemberInCountrySkilledWorkersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberOutCountryNationalityDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<OutCountryPerNationalityCollectionModel> {
            return localVarFp.apiCrewmemberOutCountryNationalityDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberOutCountryNationalitySkilledWorkersGet(options?: any): AxiosPromise<OutCountryPerNationalityCollectionModel> {
            return localVarFp.apiCrewmemberOutCountryNationalitySkilledWorkersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPassportExpiryGet(options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberPassportExpiryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPassportExpiryUserIdGet(userId: string, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmemberPassportExpiryUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPhotoIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmemberPhotoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {boolean} [adminViewOnly] 
         * @param {string} [documentNumber] 
         * @param {string} [expiryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPhotoPost(filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiCrewmemberPhotoPost(filename, crewMemberId, discriminator, document, reference, adminViewOnly, documentNumber, expiryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberPost(crewMemberUpsertModel?: CrewMemberUpsertModel, options?: any): AxiosPromise<CrewMemberModel> {
            return localVarFp.apiCrewmemberPost(crewMemberUpsertModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrewMemberApi - object-oriented interface
 * @export
 * @class CrewMemberApi
 * @extends {BaseAPI}
 */
export class CrewMemberApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberActiveSkilledWorkerUserIdGet(userId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberActiveSkilledWorkerUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberActiveUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberActiveUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberActiveUserIdGet(userId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberActiveUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberCrewMemberIdDelete(crewMemberId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberCrewMemberIdDelete(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberCrewMemberIdGet(crewMemberId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberCrewMemberIdPut(crewMemberId: string, crewMemberUpsertModel?: CrewMemberUpsertModel, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberCrewMemberIdPut(crewMemberId, crewMemberUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberDiscriminatorGet(discriminator: ContractType, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberDonotuseAgencyIdGet(agencyId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberDonotuseAgencyIdGet(agencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberDonotuseGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberDonotuseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberEmailEmailAddressGet(emailAddress: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberEmailEmailAddressGet(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportActiveGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportActiveGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobRoleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportActiveJobroleJobRoleIdGet(jobRoleId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportActiveJobroleJobRoleIdGet(jobRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportActiveNationalityCountryIdGet(countryId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportActiveNationalityCountryIdGet(countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportAvailableGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportAvailableGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobRoleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportAvailableJobroleJobRoleIdGet(jobRoleId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportAvailableJobroleJobRoleIdGet(jobRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportAvailableNationalityCountryIdGet(countryId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportAvailableNationalityCountryIdGet(countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberExportSkilledWorkersGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberExportSkilledWorkersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberInCountryDiscriminatorGet(discriminator: ContractType, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberInCountryDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberInCountryGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberInCountryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberInCountryNationalityDiscriminatorGet(discriminator: ContractType, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberInCountryNationalityDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberInCountryNationalitySkilledWorkersGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberInCountryNationalitySkilledWorkersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberInCountrySkilledWorkersGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberInCountrySkilledWorkersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberOutCountryNationalityDiscriminatorGet(discriminator: ContractType, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberOutCountryNationalityDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberOutCountryNationalitySkilledWorkersGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberOutCountryNationalitySkilledWorkersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberPassportExpiryGet(options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberPassportExpiryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberPassportExpiryUserIdGet(userId: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberPassportExpiryUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberPhotoIdGet(id: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberPhotoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [crewMemberId] 
     * @param {ContractType} [discriminator] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {boolean} [adminViewOnly] 
     * @param {string} [documentNumber] 
     * @param {string} [expiryDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberPhotoPost(filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberPhotoPost(filename, crewMemberId, discriminator, document, reference, adminViewOnly, documentNumber, expiryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CrewMemberUpsertModel} [crewMemberUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberApi
     */
    public apiCrewmemberPost(crewMemberUpsertModel?: CrewMemberUpsertModel, options?: any) {
        return CrewMemberApiFp(this.configuration).apiCrewmemberPost(crewMemberUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrewMemberCertificateApi - axios parameter creator
 * @export
 */
export const CrewMemberCertificateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete: async (crewMemberCertificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberCertificateId' is not null or undefined
            assertParamExists('apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete', 'crewMemberCertificateId', crewMemberCertificateId)
            const localVarPath = `/api/crewmembercertificate/archive/{crewMemberCertificateId}`
                .replace(`{${"crewMemberCertificateId"}}`, encodeURIComponent(String(crewMemberCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCertificateIdDelete: async (crewMemberCertificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberCertificateId' is not null or undefined
            assertParamExists('apiCrewmembercertificateCrewMemberCertificateIdDelete', 'crewMemberCertificateId', crewMemberCertificateId)
            const localVarPath = `/api/crewmembercertificate/{crewMemberCertificateId}`
                .replace(`{${"crewMemberCertificateId"}}`, encodeURIComponent(String(crewMemberCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCertificateIdGet: async (crewMemberCertificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberCertificateId' is not null or undefined
            assertParamExists('apiCrewmembercertificateCrewMemberCertificateIdGet', 'crewMemberCertificateId', crewMemberCertificateId)
            const localVarPath = `/api/crewmembercertificate/{crewMemberCertificateId}`
                .replace(`{${"crewMemberCertificateId"}}`, encodeURIComponent(String(crewMemberCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {CrewMemberCertificateUpdateModel} [crewMemberCertificateUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCertificateIdPut: async (crewMemberCertificateId: string, crewMemberCertificateUpdateModel?: CrewMemberCertificateUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberCertificateId' is not null or undefined
            assertParamExists('apiCrewmembercertificateCrewMemberCertificateIdPut', 'crewMemberCertificateId', crewMemberCertificateId)
            const localVarPath = `/api/crewmembercertificate/{crewMemberCertificateId}`
                .replace(`{${"crewMemberCertificateId"}}`, encodeURIComponent(String(crewMemberCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberCertificateUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet: async (crewMemberId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet', 'crewMemberId', crewMemberId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmembercertificate/crew-member/{crewMemberId}/{discriminator}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmembercertificateCrewMemberCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmembercertificate/crew-member/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateExpiringGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmembercertificate/expiring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateExpiringUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCrewmembercertificateExpiringUserIdGet', 'userId', userId)
            const localVarPath = `/api/crewmembercertificate/expiring/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {string} [certificateTypeId] 
         * @param {any} [certificate] 
         * @param {ContractType} [discriminator] 
         * @param {string} [reference] 
         * @param {string} [startDate] 
         * @param {string} [expiryDate] 
         * @param {boolean} [dealtWith] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificatePost: async (filename: string, crewMemberId?: string, certificateTypeId?: string, certificate?: any, discriminator?: ContractType, reference?: string, startDate?: string, expiryDate?: string, dealtWith?: boolean, archived?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiCrewmembercertificatePost', 'filename', filename)
            const localVarPath = `/api/crewmembercertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (crewMemberId !== undefined) { 
                localVarFormParams.append('CrewMemberId', new Blob([JSON.stringify(crewMemberId)], { type: "application/json", }));
            }
    
            if (certificateTypeId !== undefined) { 
                localVarFormParams.append('CertificateTypeId', new Blob([JSON.stringify(certificateTypeId)], { type: "application/json", }));
            }
    
            if (certificate !== undefined) { 
                localVarFormParams.append('Certificate', certificate as any);
            }
    
            if (discriminator !== undefined) { 
                localVarFormParams.append('Discriminator', new Blob([JSON.stringify(discriminator)], { type: "application/json", }));
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (startDate !== undefined) { 
                localVarFormParams.append('StartDate', startDate as any);
            }
    
            if (expiryDate !== undefined) { 
                localVarFormParams.append('ExpiryDate', expiryDate as any);
            }
    
            if (dealtWith !== undefined) { 
                localVarFormParams.append('DealtWith', dealtWith as any);
            }
    
            if (archived !== undefined) { 
                localVarFormParams.append('Archived', archived as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete: async (crewMemberCertificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberCertificateId' is not null or undefined
            assertParamExists('apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete', 'crewMemberCertificateId', crewMemberCertificateId)
            const localVarPath = `/api/crewmembercertificate/unarchive/{crewMemberCertificateId}`
                .replace(`{${"crewMemberCertificateId"}}`, encodeURIComponent(String(crewMemberCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrewMemberCertificateApi - functional programming interface
 * @export
 */
export const CrewMemberCertificateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrewMemberCertificateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(crewMemberCertificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateCrewMemberCertificateIdDelete(crewMemberCertificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateCrewMemberCertificateIdGet(crewMemberCertificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberCertificateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateCrewMemberCertificateIdGet(crewMemberCertificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {CrewMemberCertificateUpdateModel} [crewMemberCertificateUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateCrewMemberCertificateIdPut(crewMemberCertificateId: string, crewMemberCertificateUpdateModel?: CrewMemberCertificateUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberCertificateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateCrewMemberCertificateIdPut(crewMemberCertificateId, crewMemberCertificateUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberCertificateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberCertificateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateCrewMemberCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateExpiringGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberCertificateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateExpiringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateExpiringUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberCertificateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateExpiringUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {string} [certificateTypeId] 
         * @param {any} [certificate] 
         * @param {ContractType} [discriminator] 
         * @param {string} [reference] 
         * @param {string} [startDate] 
         * @param {string} [expiryDate] 
         * @param {boolean} [dealtWith] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificatePost(filename: string, crewMemberId?: string, certificateTypeId?: string, certificate?: any, discriminator?: ContractType, reference?: string, startDate?: string, expiryDate?: string, dealtWith?: boolean, archived?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberCertificateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificatePost(filename, crewMemberId, certificateTypeId, certificate, discriminator, reference, startDate, expiryDate, dealtWith, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(crewMemberCertificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrewMemberCertificateApi - factory interface
 * @export
 */
export const CrewMemberCertificateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrewMemberCertificateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(crewMemberCertificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmembercertificateCrewMemberCertificateIdDelete(crewMemberCertificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCertificateIdGet(crewMemberCertificateId: string, options?: any): AxiosPromise<CrewMemberCertificateModel> {
            return localVarFp.apiCrewmembercertificateCrewMemberCertificateIdGet(crewMemberCertificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {CrewMemberCertificateUpdateModel} [crewMemberCertificateUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCertificateIdPut(crewMemberCertificateId: string, crewMemberCertificateUpdateModel?: CrewMemberCertificateUpdateModel, options?: any): AxiosPromise<CrewMemberCertificateModel> {
            return localVarFp.apiCrewmembercertificateCrewMemberCertificateIdPut(crewMemberCertificateId, crewMemberCertificateUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberCertificateModel>> {
            return localVarFp.apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<Array<CrewMemberCertificateModel>> {
            return localVarFp.apiCrewmembercertificateCrewMemberCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateExpiringGet(options?: any): AxiosPromise<Array<CrewMemberCertificateModel>> {
            return localVarFp.apiCrewmembercertificateExpiringGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateExpiringUserIdGet(userId: string, options?: any): AxiosPromise<Array<CrewMemberCertificateModel>> {
            return localVarFp.apiCrewmembercertificateExpiringUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {string} [certificateTypeId] 
         * @param {any} [certificate] 
         * @param {ContractType} [discriminator] 
         * @param {string} [reference] 
         * @param {string} [startDate] 
         * @param {string} [expiryDate] 
         * @param {boolean} [dealtWith] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificatePost(filename: string, crewMemberId?: string, certificateTypeId?: string, certificate?: any, discriminator?: ContractType, reference?: string, startDate?: string, expiryDate?: string, dealtWith?: boolean, archived?: boolean, options?: any): AxiosPromise<CrewMemberCertificateModel> {
            return localVarFp.apiCrewmembercertificatePost(filename, crewMemberId, certificateTypeId, certificate, discriminator, reference, startDate, expiryDate, dealtWith, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(crewMemberCertificateId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrewMemberCertificateApi - object-oriented interface
 * @export
 * @class CrewMemberCertificateApi
 * @extends {BaseAPI}
 */
export class CrewMemberCertificateApi extends BaseAPI {
    /**
     * 
     * @param {string} crewMemberCertificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(crewMemberCertificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberCertificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateCrewMemberCertificateIdDelete(crewMemberCertificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberCertificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateCrewMemberCertificateIdGet(crewMemberCertificateId: string, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateCrewMemberCertificateIdGet(crewMemberCertificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberCertificateId 
     * @param {CrewMemberCertificateUpdateModel} [crewMemberCertificateUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateCrewMemberCertificateIdPut(crewMemberCertificateId: string, crewMemberCertificateUpdateModel?: CrewMemberCertificateUpdateModel, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateCrewMemberCertificateIdPut(crewMemberCertificateId, crewMemberCertificateUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateCrewMemberCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateExpiringGet(options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateExpiringGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateExpiringUserIdGet(userId: string, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateExpiringUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [crewMemberId] 
     * @param {string} [certificateTypeId] 
     * @param {any} [certificate] 
     * @param {ContractType} [discriminator] 
     * @param {string} [reference] 
     * @param {string} [startDate] 
     * @param {string} [expiryDate] 
     * @param {boolean} [dealtWith] 
     * @param {boolean} [archived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificatePost(filename: string, crewMemberId?: string, certificateTypeId?: string, certificate?: any, discriminator?: ContractType, reference?: string, startDate?: string, expiryDate?: string, dealtWith?: boolean, archived?: boolean, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificatePost(filename, crewMemberId, certificateTypeId, certificate, discriminator, reference, startDate, expiryDate, dealtWith, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberCertificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberCertificateApi
     */
    public apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(crewMemberCertificateId: string, options?: any) {
        return CrewMemberCertificateApiFp(this.configuration).apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(crewMemberCertificateId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrewMemberDocumentApi - axios parameter creator
 * @export
 */
export const CrewMemberDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete: async (crewMemberDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberDocumentId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete', 'crewMemberDocumentId', crewMemberDocumentId)
            const localVarPath = `/api/crewmemberdocument/archive/{crewMemberDocumentId}`
                .replace(`{${"crewMemberDocumentId"}}`, encodeURIComponent(String(crewMemberDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet: async (crewMemberId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet', 'crewMemberId', crewMemberId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmemberdocument/crew-member/{crewMemberId}/{discriminator}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmemberdocument/crew-member/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberDocumentIdDelete: async (crewMemberDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberDocumentId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberDocumentIdDelete', 'crewMemberDocumentId', crewMemberDocumentId)
            const localVarPath = `/api/crewmemberdocument/{crewMemberDocumentId}`
                .replace(`{${"crewMemberDocumentId"}}`, encodeURIComponent(String(crewMemberDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberDocumentIdGet: async (crewMemberDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberDocumentId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberDocumentIdGet', 'crewMemberDocumentId', crewMemberDocumentId)
            const localVarPath = `/api/crewmemberdocument/{crewMemberDocumentId}`
                .replace(`{${"crewMemberDocumentId"}}`, encodeURIComponent(String(crewMemberDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {CrewMemberDocumentUpdateModel} [crewMemberDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberDocumentIdPut: async (crewMemberDocumentId: string, crewMemberDocumentUpdateModel?: CrewMemberDocumentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberDocumentId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberDocumentIdPut', 'crewMemberDocumentId', crewMemberDocumentId)
            const localVarPath = `/api/crewmemberdocument/{crewMemberDocumentId}`
                .replace(`{${"crewMemberDocumentId"}}`, encodeURIComponent(String(crewMemberDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberDocumentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet: async (crewMemberId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet', 'crewMemberId', crewMemberId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmemberdocument/crew-member/non-admin-view/{crewMemberId}/{discriminator}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmemberdocument/crew-member/non-admin-view/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {boolean} [adminViewOnly] 
         * @param {string} [documentNumber] 
         * @param {string} [expiryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentPost: async (filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiCrewmemberdocumentPost', 'filename', filename)
            const localVarPath = `/api/crewmemberdocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (crewMemberId !== undefined) { 
                localVarFormParams.append('CrewMemberId', new Blob([JSON.stringify(crewMemberId)], { type: "application/json", }));
            }
    
            if (discriminator !== undefined) { 
                localVarFormParams.append('Discriminator', new Blob([JSON.stringify(discriminator)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (adminViewOnly !== undefined) { 
                localVarFormParams.append('AdminViewOnly', adminViewOnly as any);
            }
    
            if (documentNumber !== undefined) { 
                localVarFormParams.append('DocumentNumber', documentNumber as any);
            }
    
            if (expiryDate !== undefined) { 
                localVarFormParams.append('ExpiryDate', expiryDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete: async (crewMemberDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberDocumentId' is not null or undefined
            assertParamExists('apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete', 'crewMemberDocumentId', crewMemberDocumentId)
            const localVarPath = `/api/crewmemberdocument/unarchive/{crewMemberDocumentId}`
                .replace(`{${"crewMemberDocumentId"}}`, encodeURIComponent(String(crewMemberDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrewMemberDocumentApi - functional programming interface
 * @export
 */
export const CrewMemberDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrewMemberDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(crewMemberDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberDocumentIdDelete(crewMemberDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberDocumentIdGet(crewMemberDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberDocumentIdGet(crewMemberDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {CrewMemberDocumentUpdateModel} [crewMemberDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberDocumentIdPut(crewMemberDocumentId: string, crewMemberDocumentUpdateModel?: CrewMemberDocumentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberDocumentIdPut(crewMemberDocumentId, crewMemberDocumentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {boolean} [adminViewOnly] 
         * @param {string} [documentNumber] 
         * @param {string} [expiryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentPost(filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentPost(filename, crewMemberId, discriminator, document, reference, adminViewOnly, documentNumber, expiryDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(crewMemberDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrewMemberDocumentApi - factory interface
 * @export
 */
export const CrewMemberDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrewMemberDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(crewMemberDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberDocumentModel>> {
            return localVarFp.apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<Array<CrewMemberDocumentModel>> {
            return localVarFp.apiCrewmemberdocumentCrewMemberCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmemberdocumentCrewMemberDocumentIdDelete(crewMemberDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberDocumentIdGet(crewMemberDocumentId: string, options?: any): AxiosPromise<CrewMemberDocumentModel> {
            return localVarFp.apiCrewmemberdocumentCrewMemberDocumentIdGet(crewMemberDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {CrewMemberDocumentUpdateModel} [crewMemberDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberDocumentIdPut(crewMemberDocumentId: string, crewMemberDocumentUpdateModel?: CrewMemberDocumentUpdateModel, options?: any): AxiosPromise<CrewMemberDocumentModel> {
            return localVarFp.apiCrewmemberdocumentCrewMemberDocumentIdPut(crewMemberDocumentId, crewMemberDocumentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberDocumentModel>> {
            return localVarFp.apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<Array<CrewMemberDocumentModel>> {
            return localVarFp.apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {boolean} [adminViewOnly] 
         * @param {string} [documentNumber] 
         * @param {string} [expiryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentPost(filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options?: any): AxiosPromise<CrewMemberDocumentModel> {
            return localVarFp.apiCrewmemberdocumentPost(filename, crewMemberId, discriminator, document, reference, adminViewOnly, documentNumber, expiryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(crewMemberDocumentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrewMemberDocumentApi - object-oriented interface
 * @export
 * @class CrewMemberDocumentApi
 * @extends {BaseAPI}
 */
export class CrewMemberDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} crewMemberDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(crewMemberDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberDocumentIdDelete(crewMemberDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberDocumentIdGet(crewMemberDocumentId: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberDocumentIdGet(crewMemberDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberDocumentId 
     * @param {CrewMemberDocumentUpdateModel} [crewMemberDocumentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberDocumentIdPut(crewMemberDocumentId: string, crewMemberDocumentUpdateModel?: CrewMemberDocumentUpdateModel, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberDocumentIdPut(crewMemberDocumentId, crewMemberDocumentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [crewMemberId] 
     * @param {ContractType} [discriminator] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {boolean} [adminViewOnly] 
     * @param {string} [documentNumber] 
     * @param {string} [expiryDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentPost(filename: string, crewMemberId?: string, discriminator?: ContractType, document?: any, reference?: string, adminViewOnly?: boolean, documentNumber?: string, expiryDate?: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentPost(filename, crewMemberId, discriminator, document, reference, adminViewOnly, documentNumber, expiryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberDocumentApi
     */
    public apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(crewMemberDocumentId: string, options?: any) {
        return CrewMemberDocumentApiFp(this.configuration).apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(crewMemberDocumentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrewMemberNoteApi - axios parameter creator
 * @export
 */
export const CrewMemberNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet: async (crewMemberId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet', 'crewMemberId', crewMemberId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmembernote/crew-member/{crewMemberId}/{discriminator}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmembernote/crew-member/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet: async (crewMemberId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet', 'crewMemberId', crewMemberId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/crewmembernote/crew-member/non-admin-view/{crewMemberId}/{discriminator}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/crewmembernote/crew-member/non-admin-view/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNoteIdDelete: async (crewMemberNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberNoteId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberNoteIdDelete', 'crewMemberNoteId', crewMemberNoteId)
            const localVarPath = `/api/crewmembernote/{crewMemberNoteId}`
                .replace(`{${"crewMemberNoteId"}}`, encodeURIComponent(String(crewMemberNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNoteIdGet: async (crewMemberNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberNoteId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberNoteIdGet', 'crewMemberNoteId', crewMemberNoteId)
            const localVarPath = `/api/crewmembernote/{crewMemberNoteId}`
                .replace(`{${"crewMemberNoteId"}}`, encodeURIComponent(String(crewMemberNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {CrewMemberNoteUpdateModel} [crewMemberNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNoteIdPut: async (crewMemberNoteId: string, crewMemberNoteUpdateModel?: CrewMemberNoteUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberNoteId' is not null or undefined
            assertParamExists('apiCrewmembernoteCrewMemberNoteIdPut', 'crewMemberNoteId', crewMemberNoteId)
            const localVarPath = `/api/crewmembernote/{crewMemberNoteId}`
                .replace(`{${"crewMemberNoteId"}}`, encodeURIComponent(String(crewMemberNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberNoteUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {boolean} [adminViewOnly] 
         * @param {Array<CrewMemberNoteEvidenceModel>} [crewMemberNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernotePost: async (crewMemberId?: string, discriminator?: ContractType, evidence?: Array<any>, title?: string, note?: string, adminViewOnly?: boolean, crewMemberNoteEvidence?: Array<CrewMemberNoteEvidenceModel>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmembernote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (crewMemberId !== undefined) { 
                localVarFormParams.append('CrewMemberId', new Blob([JSON.stringify(crewMemberId)], { type: "application/json", }));
            }
    
            if (discriminator !== undefined) { 
                localVarFormParams.append('Discriminator', new Blob([JSON.stringify(discriminator)], { type: "application/json", }));
            }
                if (evidence) {
                evidence.forEach((element) => {
                    localVarFormParams.append('Evidence', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('Note', note as any);
            }
    
            if (adminViewOnly !== undefined) { 
                localVarFormParams.append('AdminViewOnly', adminViewOnly as any);
            }
                if (crewMemberNoteEvidence) {
                localVarFormParams.append(crewMemberNoteEvidence.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrewMemberNoteApi - functional programming interface
 * @export
 */
export const CrewMemberNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrewMemberNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberNoteIdDelete(crewMemberNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberNoteIdDelete(crewMemberNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberNoteIdGet(crewMemberNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberNoteIdGet(crewMemberNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {CrewMemberNoteUpdateModel} [crewMemberNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernoteCrewMemberNoteIdPut(crewMemberNoteId: string, crewMemberNoteUpdateModel?: CrewMemberNoteUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernoteCrewMemberNoteIdPut(crewMemberNoteId, crewMemberNoteUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {boolean} [adminViewOnly] 
         * @param {Array<CrewMemberNoteEvidenceModel>} [crewMemberNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembernotePost(crewMemberId?: string, discriminator?: ContractType, evidence?: Array<any>, title?: string, note?: string, adminViewOnly?: boolean, crewMemberNoteEvidence?: Array<CrewMemberNoteEvidenceModel>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembernotePost(crewMemberId, discriminator, evidence, title, note, adminViewOnly, crewMemberNoteEvidence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrewMemberNoteApi - factory interface
 * @export
 */
export const CrewMemberNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrewMemberNoteApiFp(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberNoteModel>> {
            return localVarFp.apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<Array<CrewMemberNoteModel>> {
            return localVarFp.apiCrewmembernoteCrewMemberCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<CrewMemberNoteModel>> {
            return localVarFp.apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<Array<CrewMemberNoteModel>> {
            return localVarFp.apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNoteIdDelete(crewMemberNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmembernoteCrewMemberNoteIdDelete(crewMemberNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNoteIdGet(crewMemberNoteId: string, options?: any): AxiosPromise<CrewMemberNoteModel> {
            return localVarFp.apiCrewmembernoteCrewMemberNoteIdGet(crewMemberNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberNoteId 
         * @param {CrewMemberNoteUpdateModel} [crewMemberNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernoteCrewMemberNoteIdPut(crewMemberNoteId: string, crewMemberNoteUpdateModel?: CrewMemberNoteUpdateModel, options?: any): AxiosPromise<CrewMemberNoteModel> {
            return localVarFp.apiCrewmembernoteCrewMemberNoteIdPut(crewMemberNoteId, crewMemberNoteUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [crewMemberId] 
         * @param {ContractType} [discriminator] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {boolean} [adminViewOnly] 
         * @param {Array<CrewMemberNoteEvidenceModel>} [crewMemberNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembernotePost(crewMemberId?: string, discriminator?: ContractType, evidence?: Array<any>, title?: string, note?: string, adminViewOnly?: boolean, crewMemberNoteEvidence?: Array<CrewMemberNoteEvidenceModel>, options?: any): AxiosPromise<CrewMemberNoteModel> {
            return localVarFp.apiCrewmembernotePost(crewMemberId, discriminator, evidence, title, note, adminViewOnly, crewMemberNoteEvidence, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrewMemberNoteApi - object-oriented interface
 * @export
 * @class CrewMemberNoteApi
 * @extends {BaseAPI}
 */
export class CrewMemberNoteApi extends BaseAPI {
    /**
     * 
     * @param {string} crewMemberId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberCrewMemberIdGet(crewMemberId: string, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId: string, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberNoteIdDelete(crewMemberNoteId: string, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberNoteIdDelete(crewMemberNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberNoteIdGet(crewMemberNoteId: string, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberNoteIdGet(crewMemberNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberNoteId 
     * @param {CrewMemberNoteUpdateModel} [crewMemberNoteUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernoteCrewMemberNoteIdPut(crewMemberNoteId: string, crewMemberNoteUpdateModel?: CrewMemberNoteUpdateModel, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernoteCrewMemberNoteIdPut(crewMemberNoteId, crewMemberNoteUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [crewMemberId] 
     * @param {ContractType} [discriminator] 
     * @param {Array<any>} [evidence] 
     * @param {string} [title] 
     * @param {string} [note] 
     * @param {boolean} [adminViewOnly] 
     * @param {Array<CrewMemberNoteEvidenceModel>} [crewMemberNoteEvidence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberNoteApi
     */
    public apiCrewmembernotePost(crewMemberId?: string, discriminator?: ContractType, evidence?: Array<any>, title?: string, note?: string, adminViewOnly?: boolean, crewMemberNoteEvidence?: Array<CrewMemberNoteEvidenceModel>, options?: any) {
        return CrewMemberNoteApiFp(this.configuration).apiCrewmembernotePost(crewMemberId, discriminator, evidence, title, note, adminViewOnly, crewMemberNoteEvidence, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrewMemberVesselEnquiryApi - axios parameter creator
 * @export
 */
export const CrewMemberVesselEnquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete: async (crewMemberVesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberVesselEnquiryId' is not null or undefined
            assertParamExists('apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete', 'crewMemberVesselEnquiryId', crewMemberVesselEnquiryId)
            const localVarPath = `/api/crewmembervesselenquiry/{crewMemberVesselEnquiryId}`
                .replace(`{${"crewMemberVesselEnquiryId"}}`, encodeURIComponent(String(crewMemberVesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet: async (crewMemberVesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberVesselEnquiryId' is not null or undefined
            assertParamExists('apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet', 'crewMemberVesselEnquiryId', crewMemberVesselEnquiryId)
            const localVarPath = `/api/crewmembervesselenquiry/{crewMemberVesselEnquiryId}`
                .replace(`{${"crewMemberVesselEnquiryId"}}`, encodeURIComponent(String(crewMemberVesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {CrewMemberVesselEnquiryUpdateModel} [crewMemberVesselEnquiryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut: async (crewMemberVesselEnquiryId: string, crewMemberVesselEnquiryUpdateModel?: CrewMemberVesselEnquiryUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberVesselEnquiryId' is not null or undefined
            assertParamExists('apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut', 'crewMemberVesselEnquiryId', crewMemberVesselEnquiryId)
            const localVarPath = `/api/crewmembervesselenquiry/{crewMemberVesselEnquiryId}`
                .replace(`{${"crewMemberVesselEnquiryId"}}`, encodeURIComponent(String(crewMemberVesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberVesselEnquiryUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {ContractType} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet: async (crewMemberVesselEnquiryId: string, filter: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberVesselEnquiryId' is not null or undefined
            assertParamExists('apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet', 'crewMemberVesselEnquiryId', crewMemberVesselEnquiryId)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet', 'filter', filter)
            const localVarPath = `/api/crewmembervesselenquiry/{crewMemberVesselEnquiryId}/recommendation/{filter}`
                .replace(`{${"crewMemberVesselEnquiryId"}}`, encodeURIComponent(String(crewMemberVesselEnquiryId)))
                .replace(`{${"filter"}}`, encodeURIComponent(String(filter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/crewmembervesselenquiry/enquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmembervesselenquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CrewMemberVesselEnquiryCreate} [crewMemberVesselEnquiryCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryPost: async (crewMemberVesselEnquiryCreate?: CrewMemberVesselEnquiryCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmembervesselenquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crewMemberVesselEnquiryCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [selectedYear] 
         * @param {ContractType} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquirySalaryJobRoleGet: async (selectedYear?: number, filter?: ContractType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmembervesselenquiry/salary/job-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (selectedYear !== undefined) {
                localVarQueryParameter['SelectedYear'] = selectedYear;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [selectedYear] 
         * @param {ContractType} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquirySalaryNationalityGet: async (selectedYear?: number, filter?: ContractType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/crewmembervesselenquiry/salary/nationality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (selectedYear !== undefined) {
                localVarQueryParameter['SelectedYear'] = selectedYear;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrewMemberVesselEnquiryApi - functional programming interface
 * @export
 */
export const CrewMemberVesselEnquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrewMemberVesselEnquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(crewMemberVesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(crewMemberVesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(crewMemberVesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberVesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(crewMemberVesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {CrewMemberVesselEnquiryUpdateModel} [crewMemberVesselEnquiryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(crewMemberVesselEnquiryId: string, crewMemberVesselEnquiryUpdateModel?: CrewMemberVesselEnquiryUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberVesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(crewMemberVesselEnquiryId, crewMemberVesselEnquiryUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {ContractType} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(crewMemberVesselEnquiryId: string, filter: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(crewMemberVesselEnquiryId, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberVesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrewMemberVesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CrewMemberVesselEnquiryCreate} [crewMemberVesselEnquiryCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquiryPost(crewMemberVesselEnquiryCreate?: CrewMemberVesselEnquiryCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberVesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquiryPost(crewMemberVesselEnquiryCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [selectedYear] 
         * @param {ContractType} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquirySalaryJobRoleGet(selectedYear?: number, filter?: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryPerJobRoleCollectionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquirySalaryJobRoleGet(selectedYear, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [selectedYear] 
         * @param {ContractType} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCrewmembervesselenquirySalaryNationalityGet(selectedYear?: number, filter?: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryPerNationalityCollectionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCrewmembervesselenquirySalaryNationalityGet(selectedYear, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrewMemberVesselEnquiryApi - factory interface
 * @export
 */
export const CrewMemberVesselEnquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrewMemberVesselEnquiryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(crewMemberVesselEnquiryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(crewMemberVesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(crewMemberVesselEnquiryId: string, options?: any): AxiosPromise<CrewMemberVesselEnquiryModel> {
            return localVarFp.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(crewMemberVesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {CrewMemberVesselEnquiryUpdateModel} [crewMemberVesselEnquiryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(crewMemberVesselEnquiryId: string, crewMemberVesselEnquiryUpdateModel?: CrewMemberVesselEnquiryUpdateModel, options?: any): AxiosPromise<CrewMemberVesselEnquiryModel> {
            return localVarFp.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(crewMemberVesselEnquiryId, crewMemberVesselEnquiryUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberVesselEnquiryId 
         * @param {ContractType} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(crewMemberVesselEnquiryId: string, filter: ContractType, options?: any): AxiosPromise<Array<CrewMemberModel>> {
            return localVarFp.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(crewMemberVesselEnquiryId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): AxiosPromise<Array<CrewMemberVesselEnquiryModel>> {
            return localVarFp.apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryGet(options?: any): AxiosPromise<Array<CrewMemberVesselEnquiryModel>> {
            return localVarFp.apiCrewmembervesselenquiryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CrewMemberVesselEnquiryCreate} [crewMemberVesselEnquiryCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquiryPost(crewMemberVesselEnquiryCreate?: CrewMemberVesselEnquiryCreate, options?: any): AxiosPromise<CrewMemberVesselEnquiryModel> {
            return localVarFp.apiCrewmembervesselenquiryPost(crewMemberVesselEnquiryCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [selectedYear] 
         * @param {ContractType} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquirySalaryJobRoleGet(selectedYear?: number, filter?: ContractType, options?: any): AxiosPromise<SalaryPerJobRoleCollectionModel> {
            return localVarFp.apiCrewmembervesselenquirySalaryJobRoleGet(selectedYear, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [selectedYear] 
         * @param {ContractType} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCrewmembervesselenquirySalaryNationalityGet(selectedYear?: number, filter?: ContractType, options?: any): AxiosPromise<SalaryPerNationalityCollectionModel> {
            return localVarFp.apiCrewmembervesselenquirySalaryNationalityGet(selectedYear, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrewMemberVesselEnquiryApi - object-oriented interface
 * @export
 * @class CrewMemberVesselEnquiryApi
 * @extends {BaseAPI}
 */
export class CrewMemberVesselEnquiryApi extends BaseAPI {
    /**
     * 
     * @param {string} crewMemberVesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(crewMemberVesselEnquiryId: string, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(crewMemberVesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberVesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(crewMemberVesselEnquiryId: string, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(crewMemberVesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberVesselEnquiryId 
     * @param {CrewMemberVesselEnquiryUpdateModel} [crewMemberVesselEnquiryUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(crewMemberVesselEnquiryId: string, crewMemberVesselEnquiryUpdateModel?: CrewMemberVesselEnquiryUpdateModel, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(crewMemberVesselEnquiryId, crewMemberVesselEnquiryUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberVesselEnquiryId 
     * @param {ContractType} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(crewMemberVesselEnquiryId: string, filter: ContractType, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(crewMemberVesselEnquiryId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryGet(options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CrewMemberVesselEnquiryCreate} [crewMemberVesselEnquiryCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquiryPost(crewMemberVesselEnquiryCreate?: CrewMemberVesselEnquiryCreate, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquiryPost(crewMemberVesselEnquiryCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [selectedYear] 
     * @param {ContractType} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquirySalaryJobRoleGet(selectedYear?: number, filter?: ContractType, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquirySalaryJobRoleGet(selectedYear, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [selectedYear] 
     * @param {ContractType} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewMemberVesselEnquiryApi
     */
    public apiCrewmembervesselenquirySalaryNationalityGet(selectedYear?: number, filter?: ContractType, options?: any) {
        return CrewMemberVesselEnquiryApiFp(this.configuration).apiCrewmembervesselenquirySalaryNationalityGet(selectedYear, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDocumentIdGet: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiDocumentDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/api/document/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentDocumentIdGet(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentDocumentIdGet(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDocumentIdGet(documentId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiDocumentDocumentIdGet(documentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentDocumentIdGet(documentId: string, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentDocumentIdGet(documentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobRoleApi - axios parameter creator
 * @export
 */
export const JobRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiJobroleDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/jobrole/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobrole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleJobRoleIdDelete: async (jobRoleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobRoleId' is not null or undefined
            assertParamExists('apiJobroleJobRoleIdDelete', 'jobRoleId', jobRoleId)
            const localVarPath = `/api/jobrole/{jobRoleId}`
                .replace(`{${"jobRoleId"}}`, encodeURIComponent(String(jobRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleJobRoleIdGet: async (jobRoleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobRoleId' is not null or undefined
            assertParamExists('apiJobroleJobRoleIdGet', 'jobRoleId', jobRoleId)
            const localVarPath = `/api/jobrole/{jobRoleId}`
                .replace(`{${"jobRoleId"}}`, encodeURIComponent(String(jobRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleJobRoleIdPut: async (jobRoleId: string, jobRoleUpsertModel?: JobRoleUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobRoleId' is not null or undefined
            assertParamExists('apiJobroleJobRoleIdPut', 'jobRoleId', jobRoleId)
            const localVarPath = `/api/jobrole/{jobRoleId}`
                .replace(`{${"jobRoleId"}}`, encodeURIComponent(String(jobRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobRoleUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobrolePost: async (jobRoleUpsertModel?: JobRoleUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobrole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobRoleUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobRoleApi - functional programming interface
 * @export
 */
export const JobRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJobroleDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobRoleModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobroleDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJobroleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobRoleModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobroleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJobroleJobRoleIdDelete(jobRoleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobroleJobRoleIdDelete(jobRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJobroleJobRoleIdGet(jobRoleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobRoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobroleJobRoleIdGet(jobRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJobroleJobRoleIdPut(jobRoleId: string, jobRoleUpsertModel?: JobRoleUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobRoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobroleJobRoleIdPut(jobRoleId, jobRoleUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJobrolePost(jobRoleUpsertModel?: JobRoleUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobRoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobrolePost(jobRoleUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobRoleApi - factory interface
 * @export
 */
export const JobRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobRoleApiFp(configuration)
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<JobRoleModel>> {
            return localVarFp.apiJobroleDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleGet(options?: any): AxiosPromise<Array<JobRoleModel>> {
            return localVarFp.apiJobroleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleJobRoleIdDelete(jobRoleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiJobroleJobRoleIdDelete(jobRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleJobRoleIdGet(jobRoleId: string, options?: any): AxiosPromise<JobRoleModel> {
            return localVarFp.apiJobroleJobRoleIdGet(jobRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobRoleId 
         * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobroleJobRoleIdPut(jobRoleId: string, jobRoleUpsertModel?: JobRoleUpsertModel, options?: any): AxiosPromise<JobRoleModel> {
            return localVarFp.apiJobroleJobRoleIdPut(jobRoleId, jobRoleUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJobrolePost(jobRoleUpsertModel?: JobRoleUpsertModel, options?: any): AxiosPromise<JobRoleModel> {
            return localVarFp.apiJobrolePost(jobRoleUpsertModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobRoleApi - object-oriented interface
 * @export
 * @class JobRoleApi
 * @extends {BaseAPI}
 */
export class JobRoleApi extends BaseAPI {
    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRoleApi
     */
    public apiJobroleDiscriminatorGet(discriminator: ContractType, options?: any) {
        return JobRoleApiFp(this.configuration).apiJobroleDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRoleApi
     */
    public apiJobroleGet(options?: any) {
        return JobRoleApiFp(this.configuration).apiJobroleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobRoleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRoleApi
     */
    public apiJobroleJobRoleIdDelete(jobRoleId: string, options?: any) {
        return JobRoleApiFp(this.configuration).apiJobroleJobRoleIdDelete(jobRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobRoleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRoleApi
     */
    public apiJobroleJobRoleIdGet(jobRoleId: string, options?: any) {
        return JobRoleApiFp(this.configuration).apiJobroleJobRoleIdGet(jobRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobRoleId 
     * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRoleApi
     */
    public apiJobroleJobRoleIdPut(jobRoleId: string, jobRoleUpsertModel?: JobRoleUpsertModel, options?: any) {
        return JobRoleApiFp(this.configuration).apiJobroleJobRoleIdPut(jobRoleId, jobRoleUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JobRoleUpsertModel} [jobRoleUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRoleApi
     */
    public apiJobrolePost(jobRoleUpsertModel?: JobRoleUpsertModel, options?: any) {
        return JobRoleApiFp(this.configuration).apiJobrolePost(jobRoleUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAgentlistIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportAgentlistIdGet', 'id', id)
            const localVarPath = `/api/report/agentlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportCrewmemberletterofemploymentIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportCrewmemberletterofemploymentIdGet', 'id', id)
            const localVarPath = `/api/report/crewmemberletterofemployment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportCrewmembertransferrequestformIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportCrewmembertransferrequestformIdGet', 'id', id)
            const localVarPath = `/api/report/crewmembertransferrequestform/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportEnquiryCrewmansresponsibilityIdGet', 'id', id)
            const localVarPath = `/api/report/enquiry/crewmansresponsibility/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityakanIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportEnquiryCrewmansresponsibilityakanIdGet', 'id', id)
            const localVarPath = `/api/report/enquiry/crewmansresponsibilityakan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityhindiIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportEnquiryCrewmansresponsibilityhindiIdGet', 'id', id)
            const localVarPath = `/api/report/enquiry/crewmansresponsibilityhindi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityindonesianIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportEnquiryCrewmansresponsibilityindonesianIdGet', 'id', id)
            const localVarPath = `/api/report/enquiry/crewmansresponsibilityindonesian/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilitysinhalaIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportEnquiryCrewmansresponsibilitysinhalaIdGet', 'id', id)
            const localVarPath = `/api/report/enquiry/crewmansresponsibilitysinhala/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilitytagalogIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportEnquiryCrewmansresponsibilitytagalogIdGet', 'id', id)
            const localVarPath = `/api/report/enquiry/crewmansresponsibilitytagalog/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFlightindemnityIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFlightindemnityIdGet', 'id', id)
            const localVarPath = `/api/report/flightindemnity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaakanIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaakanIdGet', 'id', id)
            const localVarPath = `/api/report/fwaakan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaenglishIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaenglishIdGet', 'id', id)
            const localVarPath = `/api/report/fwaenglish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwahindiIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwahindiIdGet', 'id', id)
            const localVarPath = `/api/report/fwahindi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaindonesiaIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaindonesiaIdGet', 'id', id)
            const localVarPath = `/api/report/fwaindonesia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwasinhalaIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwasinhalaIdGet', 'id', id)
            const localVarPath = `/api/report/fwasinhala/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledakanIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaskilledakanIdGet', 'id', id)
            const localVarPath = `/api/report/fwaskilledakan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledenglishIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaskilledenglishIdGet', 'id', id)
            const localVarPath = `/api/report/fwaskilledenglish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledhindiIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaskilledhindiIdGet', 'id', id)
            const localVarPath = `/api/report/fwaskilledhindi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledindonesianIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaskilledindonesianIdGet', 'id', id)
            const localVarPath = `/api/report/fwaskilledindonesian/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledsinhalaIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwaskilledsinhalaIdGet', 'id', id)
            const localVarPath = `/api/report/fwaskilledsinhala/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwatagalogIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportFwatagalogIdGet', 'id', id)
            const localVarPath = `/api/report/fwatagalog/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprAkanCrewIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprAkanCrewIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/akan/crew/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprAkanEnquiryIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprAkanEnquiryIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/akan/enquiry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprCrewIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprCrewIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/crew/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprEnquiryIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprEnquiryIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/enquiry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprHindiCrewIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprHindiCrewIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/hindi/crew/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprHindiEnquiryIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprHindiEnquiryIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/hindi/enquiry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprSinhalaCrewIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprSinhalaCrewIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/sinhala/crew/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprSinhalaEnquiryIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprSinhalaEnquiryIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/sinhala/enquiry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprTagalogCrewIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprTagalogCrewIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/tagalog/crew/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprTagalogEnquiryIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportGdprTagalogEnquiryIdGet', 'id', id)
            const localVarPath = `/api/report/gdpr/tagalog/enquiry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportInvitationIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportInvitationIdGet', 'id', id)
            const localVarPath = `/api/report/invitation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLetterbhcaccraIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportLetterbhcaccraIdGet', 'id', id)
            const localVarPath = `/api/report/letterbhcaccra/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLetterbhcindiaIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportLetterbhcindiaIdGet', 'id', id)
            const localVarPath = `/api/report/letterbhcindia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLetterbhcsrilankaIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportLetterbhcsrilankaIdGet', 'id', id)
            const localVarPath = `/api/report/letterbhcsrilanka/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLwworkeragreementIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportLwworkeragreementIdGet', 'id', id)
            const localVarPath = `/api/report/lwworkeragreement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLwworkeragreementakanIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportLwworkeragreementakanIdGet', 'id', id)
            const localVarPath = `/api/report/lwworkeragreementakan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportNextofkinIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportNextofkinIdGet', 'id', id)
            const localVarPath = `/api/report/nextofkin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOktoboardIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportOktoboardIdGet', 'id', id)
            const localVarPath = `/api/report/oktoboard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportSeaenglishIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportSeaenglishIdGet', 'id', id)
            const localVarPath = `/api/report/seaenglish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportUndertakingIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportUndertakingIdGet', 'id', id)
            const localVarPath = `/api/report/undertaking/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportVesselownerinstructionIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportVesselownerinstructionIdGet', 'id', id)
            const localVarPath = `/api/report/vesselownerinstruction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportVesseltermsconditionsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportVesseltermsconditionsIdGet', 'id', id)
            const localVarPath = `/api/report/vesseltermsconditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportVesselvmsrequestformIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReportVesselvmsrequestformIdGet', 'id', id)
            const localVarPath = `/api/report/vesselvmsrequestform/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportAgentlistIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportAgentlistIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportCrewmemberletterofemploymentIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportCrewmemberletterofemploymentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportCrewmembertransferrequestformIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportCrewmembertransferrequestformIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportEnquiryCrewmansresponsibilityIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportEnquiryCrewmansresponsibilityIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportEnquiryCrewmansresponsibilityakanIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportEnquiryCrewmansresponsibilityakanIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportEnquiryCrewmansresponsibilityhindiIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportEnquiryCrewmansresponsibilityhindiIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFlightindemnityIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFlightindemnityIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaakanIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaakanIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaenglishIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaenglishIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwahindiIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwahindiIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaindonesiaIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaindonesiaIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwasinhalaIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwasinhalaIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaskilledakanIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaskilledakanIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaskilledenglishIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaskilledenglishIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaskilledhindiIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaskilledhindiIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaskilledindonesianIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaskilledindonesianIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwaskilledsinhalaIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwaskilledsinhalaIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportFwatagalogIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportFwatagalogIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprAkanCrewIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprAkanCrewIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprAkanEnquiryIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprAkanEnquiryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprCrewIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprCrewIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprEnquiryIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprEnquiryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprHindiCrewIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprHindiCrewIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprHindiEnquiryIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprHindiEnquiryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprSinhalaCrewIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprSinhalaCrewIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprSinhalaEnquiryIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprSinhalaEnquiryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprTagalogCrewIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprTagalogCrewIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGdprTagalogEnquiryIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGdprTagalogEnquiryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportInvitationIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportInvitationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportLetterbhcaccraIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportLetterbhcaccraIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportLetterbhcindiaIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportLetterbhcindiaIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportLetterbhcsrilankaIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportLetterbhcsrilankaIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportLwworkeragreementIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportLwworkeragreementIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportLwworkeragreementakanIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportLwworkeragreementakanIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportNextofkinIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportNextofkinIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportOktoboardIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportOktoboardIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportSeaenglishIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportSeaenglishIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportUndertakingIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportUndertakingIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportVesselownerinstructionIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportVesselownerinstructionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportVesseltermsconditionsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportVesseltermsconditionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportVesselvmsrequestformIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportVesselvmsrequestformIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAgentlistIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportAgentlistIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportCrewmemberletterofemploymentIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportCrewmemberletterofemploymentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportCrewmembertransferrequestformIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportCrewmembertransferrequestformIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportEnquiryCrewmansresponsibilityIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityakanIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportEnquiryCrewmansresponsibilityakanIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityhindiIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportEnquiryCrewmansresponsibilityhindiIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFlightindemnityIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFlightindemnityIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaakanIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaakanIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaenglishIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaenglishIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwahindiIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwahindiIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaindonesiaIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaindonesiaIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwasinhalaIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwasinhalaIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledakanIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaskilledakanIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledenglishIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaskilledenglishIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledhindiIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaskilledhindiIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledindonesianIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaskilledindonesianIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwaskilledsinhalaIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwaskilledsinhalaIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportFwatagalogIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportFwatagalogIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprAkanCrewIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprAkanCrewIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprAkanEnquiryIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprAkanEnquiryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprCrewIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprCrewIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprEnquiryIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprEnquiryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprHindiCrewIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprHindiCrewIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprHindiEnquiryIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprHindiEnquiryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprSinhalaCrewIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprSinhalaCrewIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprSinhalaEnquiryIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprSinhalaEnquiryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprTagalogCrewIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprTagalogCrewIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGdprTagalogEnquiryIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGdprTagalogEnquiryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportInvitationIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportInvitationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLetterbhcaccraIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportLetterbhcaccraIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLetterbhcindiaIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportLetterbhcindiaIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLetterbhcsrilankaIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportLetterbhcsrilankaIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLwworkeragreementIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportLwworkeragreementIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLwworkeragreementakanIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportLwworkeragreementakanIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportNextofkinIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportNextofkinIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOktoboardIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportOktoboardIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportSeaenglishIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportSeaenglishIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportUndertakingIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportUndertakingIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportVesselownerinstructionIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportVesselownerinstructionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportVesseltermsconditionsIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportVesseltermsconditionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportVesselvmsrequestformIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportVesselvmsrequestformIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportAgentlistIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportAgentlistIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportCrewmemberletterofemploymentIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportCrewmemberletterofemploymentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportCrewmembertransferrequestformIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportCrewmembertransferrequestformIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportEnquiryCrewmansresponsibilityIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportEnquiryCrewmansresponsibilityIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportEnquiryCrewmansresponsibilityakanIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportEnquiryCrewmansresponsibilityakanIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportEnquiryCrewmansresponsibilityhindiIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportEnquiryCrewmansresponsibilityhindiIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFlightindemnityIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFlightindemnityIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaakanIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaakanIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaenglishIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaenglishIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwahindiIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwahindiIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaindonesiaIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaindonesiaIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwasinhalaIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwasinhalaIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaskilledakanIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaskilledakanIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaskilledenglishIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaskilledenglishIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaskilledhindiIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaskilledhindiIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaskilledindonesianIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaskilledindonesianIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwaskilledsinhalaIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwaskilledsinhalaIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportFwatagalogIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportFwatagalogIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprAkanCrewIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprAkanCrewIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprAkanEnquiryIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprAkanEnquiryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprCrewIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprCrewIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprEnquiryIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprEnquiryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprHindiCrewIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprHindiCrewIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprHindiEnquiryIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprHindiEnquiryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprSinhalaCrewIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprSinhalaCrewIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprSinhalaEnquiryIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprSinhalaEnquiryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprTagalogCrewIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprTagalogCrewIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGdprTagalogEnquiryIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportGdprTagalogEnquiryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportInvitationIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportInvitationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportLetterbhcaccraIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportLetterbhcaccraIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportLetterbhcindiaIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportLetterbhcindiaIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportLetterbhcsrilankaIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportLetterbhcsrilankaIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportLwworkeragreementIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportLwworkeragreementIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportLwworkeragreementakanIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportLwworkeragreementakanIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportNextofkinIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportNextofkinIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportOktoboardIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportOktoboardIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportSeaenglishIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportSeaenglishIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportUndertakingIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportUndertakingIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportVesselownerinstructionIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportVesselownerinstructionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportVesseltermsconditionsIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportVesseltermsconditionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportVesselvmsrequestformIdGet(id: string, options?: any) {
        return ReportApiFp(this.configuration).apiReportVesselvmsrequestformIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceUpsertModel} [serviceUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicePost: async (serviceUpsertModel?: ServiceUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceServiceIdDelete: async (serviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('apiServiceServiceIdDelete', 'serviceId', serviceId)
            const localVarPath = `/api/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceServiceIdGet: async (serviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('apiServiceServiceIdGet', 'serviceId', serviceId)
            const localVarPath = `/api/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {ServiceUpsertModel} [serviceUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceServiceIdPut: async (serviceId: string, serviceUpsertModel?: ServiceUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('apiServiceServiceIdPut', 'serviceId', serviceId)
            const localVarPath = `/api/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ServiceUpsertModel} [serviceUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicePost(serviceUpsertModel?: ServiceUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicePost(serviceUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceServiceIdDelete(serviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceServiceIdDelete(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceServiceIdGet(serviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceServiceIdGet(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {ServiceUpsertModel} [serviceUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceServiceIdPut(serviceId: string, serviceUpsertModel?: ServiceUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceServiceIdPut(serviceId, serviceUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGet(options?: any): AxiosPromise<Array<ServiceModel>> {
            return localVarFp.apiServiceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ServiceUpsertModel} [serviceUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicePost(serviceUpsertModel?: ServiceUpsertModel, options?: any): AxiosPromise<ServiceModel> {
            return localVarFp.apiServicePost(serviceUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceServiceIdDelete(serviceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceServiceIdDelete(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceServiceIdGet(serviceId: string, options?: any): AxiosPromise<ServiceModel> {
            return localVarFp.apiServiceServiceIdGet(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {ServiceUpsertModel} [serviceUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceServiceIdPut(serviceId: string, serviceUpsertModel?: ServiceUpsertModel, options?: any): AxiosPromise<ServiceModel> {
            return localVarFp.apiServiceServiceIdPut(serviceId, serviceUpsertModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceGet(options?: any) {
        return ServiceApiFp(this.configuration).apiServiceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServiceUpsertModel} [serviceUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServicePost(serviceUpsertModel?: ServiceUpsertModel, options?: any) {
        return ServiceApiFp(this.configuration).apiServicePost(serviceUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceServiceIdDelete(serviceId: string, options?: any) {
        return ServiceApiFp(this.configuration).apiServiceServiceIdDelete(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceServiceIdGet(serviceId: string, options?: any) {
        return ServiceApiFp(this.configuration).apiServiceServiceIdGet(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {ServiceUpsertModel} [serviceUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceServiceIdPut(serviceId: string, serviceUpsertModel?: ServiceUpsertModel, options?: any) {
        return ServiceApiFp(this.configuration).apiServiceServiceIdPut(serviceId, serviceUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceDocumentApi - axios parameter creator
 * @export
 */
export const ServiceDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [serviceId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentPost: async (filename: string, serviceId?: string, document?: any, reference?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiServicedocumentPost', 'filename', filename)
            const localVarPath = `/api/servicedocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (serviceId !== undefined) { 
                localVarFormParams.append('ServiceId', new Blob([JSON.stringify(serviceId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceDocumentIdDelete: async (serviceDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceDocumentId' is not null or undefined
            assertParamExists('apiServicedocumentServiceDocumentIdDelete', 'serviceDocumentId', serviceDocumentId)
            const localVarPath = `/api/servicedocument/{serviceDocumentId}`
                .replace(`{${"serviceDocumentId"}}`, encodeURIComponent(String(serviceDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceDocumentIdGet: async (serviceDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceDocumentId' is not null or undefined
            assertParamExists('apiServicedocumentServiceDocumentIdGet', 'serviceDocumentId', serviceDocumentId)
            const localVarPath = `/api/servicedocument/{serviceDocumentId}`
                .replace(`{${"serviceDocumentId"}}`, encodeURIComponent(String(serviceDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {ServiceDocumentUpdateModel} [serviceDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceDocumentIdPut: async (serviceDocumentId: string, serviceDocumentUpdateModel?: ServiceDocumentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceDocumentId' is not null or undefined
            assertParamExists('apiServicedocumentServiceDocumentIdPut', 'serviceDocumentId', serviceDocumentId)
            const localVarPath = `/api/servicedocument/{serviceDocumentId}`
                .replace(`{${"serviceDocumentId"}}`, encodeURIComponent(String(serviceDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceDocumentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceServiceIdGet: async (serviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('apiServicedocumentServiceServiceIdGet', 'serviceId', serviceId)
            const localVarPath = `/api/servicedocument/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceDocumentApi - functional programming interface
 * @export
 */
export const ServiceDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [serviceId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicedocumentPost(filename: string, serviceId?: string, document?: any, reference?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicedocumentPost(filename, serviceId, document, reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicedocumentServiceDocumentIdDelete(serviceDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicedocumentServiceDocumentIdDelete(serviceDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicedocumentServiceDocumentIdGet(serviceDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicedocumentServiceDocumentIdGet(serviceDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {ServiceDocumentUpdateModel} [serviceDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicedocumentServiceDocumentIdPut(serviceDocumentId: string, serviceDocumentUpdateModel?: ServiceDocumentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicedocumentServiceDocumentIdPut(serviceDocumentId, serviceDocumentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicedocumentServiceServiceIdGet(serviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicedocumentServiceServiceIdGet(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceDocumentApi - factory interface
 * @export
 */
export const ServiceDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [serviceId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentPost(filename: string, serviceId?: string, document?: any, reference?: string, options?: any): AxiosPromise<ServiceDocumentModel> {
            return localVarFp.apiServicedocumentPost(filename, serviceId, document, reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceDocumentIdDelete(serviceDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiServicedocumentServiceDocumentIdDelete(serviceDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceDocumentIdGet(serviceDocumentId: string, options?: any): AxiosPromise<ServiceDocumentModel> {
            return localVarFp.apiServicedocumentServiceDocumentIdGet(serviceDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceDocumentId 
         * @param {ServiceDocumentUpdateModel} [serviceDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceDocumentIdPut(serviceDocumentId: string, serviceDocumentUpdateModel?: ServiceDocumentUpdateModel, options?: any): AxiosPromise<ServiceDocumentModel> {
            return localVarFp.apiServicedocumentServiceDocumentIdPut(serviceDocumentId, serviceDocumentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicedocumentServiceServiceIdGet(serviceId: string, options?: any): AxiosPromise<Array<ServiceDocumentModel>> {
            return localVarFp.apiServicedocumentServiceServiceIdGet(serviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceDocumentApi - object-oriented interface
 * @export
 * @class ServiceDocumentApi
 * @extends {BaseAPI}
 */
export class ServiceDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {string} [serviceId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceDocumentApi
     */
    public apiServicedocumentPost(filename: string, serviceId?: string, document?: any, reference?: string, options?: any) {
        return ServiceDocumentApiFp(this.configuration).apiServicedocumentPost(filename, serviceId, document, reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceDocumentApi
     */
    public apiServicedocumentServiceDocumentIdDelete(serviceDocumentId: string, options?: any) {
        return ServiceDocumentApiFp(this.configuration).apiServicedocumentServiceDocumentIdDelete(serviceDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceDocumentApi
     */
    public apiServicedocumentServiceDocumentIdGet(serviceDocumentId: string, options?: any) {
        return ServiceDocumentApiFp(this.configuration).apiServicedocumentServiceDocumentIdGet(serviceDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceDocumentId 
     * @param {ServiceDocumentUpdateModel} [serviceDocumentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceDocumentApi
     */
    public apiServicedocumentServiceDocumentIdPut(serviceDocumentId: string, serviceDocumentUpdateModel?: ServiceDocumentUpdateModel, options?: any) {
        return ServiceDocumentApiFp(this.configuration).apiServicedocumentServiceDocumentIdPut(serviceDocumentId, serviceDocumentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceDocumentApi
     */
    public apiServicedocumentServiceServiceIdGet(serviceId: string, options?: any) {
        return ServiceDocumentApiFp(this.configuration).apiServicedocumentServiceServiceIdGet(serviceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceNoteApi - axios parameter creator
 * @export
 */
export const ServiceNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [serviceId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<ServiceNoteEvidenceModel>} [serviceNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenotePost: async (serviceId?: string, evidence?: Array<any>, title?: string, note?: string, serviceNoteEvidence?: Array<ServiceNoteEvidenceModel>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/servicenote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (serviceId !== undefined) { 
                localVarFormParams.append('ServiceId', new Blob([JSON.stringify(serviceId)], { type: "application/json", }));
            }
                if (evidence) {
                evidence.forEach((element) => {
                    localVarFormParams.append('Evidence', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('Note', note as any);
            }
                if (serviceNoteEvidence) {
                localVarFormParams.append(serviceNoteEvidence.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceNoteIdDelete: async (serviceNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceNoteId' is not null or undefined
            assertParamExists('apiServicenoteServiceNoteIdDelete', 'serviceNoteId', serviceNoteId)
            const localVarPath = `/api/servicenote/{serviceNoteId}`
                .replace(`{${"serviceNoteId"}}`, encodeURIComponent(String(serviceNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceNoteIdGet: async (serviceNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceNoteId' is not null or undefined
            assertParamExists('apiServicenoteServiceNoteIdGet', 'serviceNoteId', serviceNoteId)
            const localVarPath = `/api/servicenote/{serviceNoteId}`
                .replace(`{${"serviceNoteId"}}`, encodeURIComponent(String(serviceNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {ServiceNoteUpdateModel} [serviceNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceNoteIdPut: async (serviceNoteId: string, serviceNoteUpdateModel?: ServiceNoteUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceNoteId' is not null or undefined
            assertParamExists('apiServicenoteServiceNoteIdPut', 'serviceNoteId', serviceNoteId)
            const localVarPath = `/api/servicenote/{serviceNoteId}`
                .replace(`{${"serviceNoteId"}}`, encodeURIComponent(String(serviceNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceNoteUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceServiceIdGet: async (serviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('apiServicenoteServiceServiceIdGet', 'serviceId', serviceId)
            const localVarPath = `/api/servicenote/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceNoteApi - functional programming interface
 * @export
 */
export const ServiceNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [serviceId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<ServiceNoteEvidenceModel>} [serviceNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicenotePost(serviceId?: string, evidence?: Array<any>, title?: string, note?: string, serviceNoteEvidence?: Array<ServiceNoteEvidenceModel>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicenotePost(serviceId, evidence, title, note, serviceNoteEvidence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicenoteServiceNoteIdDelete(serviceNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicenoteServiceNoteIdDelete(serviceNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicenoteServiceNoteIdGet(serviceNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicenoteServiceNoteIdGet(serviceNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {ServiceNoteUpdateModel} [serviceNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicenoteServiceNoteIdPut(serviceNoteId: string, serviceNoteUpdateModel?: ServiceNoteUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicenoteServiceNoteIdPut(serviceNoteId, serviceNoteUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicenoteServiceServiceIdGet(serviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicenoteServiceServiceIdGet(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceNoteApi - factory interface
 * @export
 */
export const ServiceNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceNoteApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [serviceId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<ServiceNoteEvidenceModel>} [serviceNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenotePost(serviceId?: string, evidence?: Array<any>, title?: string, note?: string, serviceNoteEvidence?: Array<ServiceNoteEvidenceModel>, options?: any): AxiosPromise<ServiceNoteModel> {
            return localVarFp.apiServicenotePost(serviceId, evidence, title, note, serviceNoteEvidence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceNoteIdDelete(serviceNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiServicenoteServiceNoteIdDelete(serviceNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceNoteIdGet(serviceNoteId: string, options?: any): AxiosPromise<ServiceNoteModel> {
            return localVarFp.apiServicenoteServiceNoteIdGet(serviceNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceNoteId 
         * @param {ServiceNoteUpdateModel} [serviceNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceNoteIdPut(serviceNoteId: string, serviceNoteUpdateModel?: ServiceNoteUpdateModel, options?: any): AxiosPromise<ServiceNoteModel> {
            return localVarFp.apiServicenoteServiceNoteIdPut(serviceNoteId, serviceNoteUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicenoteServiceServiceIdGet(serviceId: string, options?: any): AxiosPromise<Array<ServiceNoteModel>> {
            return localVarFp.apiServicenoteServiceServiceIdGet(serviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceNoteApi - object-oriented interface
 * @export
 * @class ServiceNoteApi
 * @extends {BaseAPI}
 */
export class ServiceNoteApi extends BaseAPI {
    /**
     * 
     * @param {string} [serviceId] 
     * @param {Array<any>} [evidence] 
     * @param {string} [title] 
     * @param {string} [note] 
     * @param {Array<ServiceNoteEvidenceModel>} [serviceNoteEvidence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceNoteApi
     */
    public apiServicenotePost(serviceId?: string, evidence?: Array<any>, title?: string, note?: string, serviceNoteEvidence?: Array<ServiceNoteEvidenceModel>, options?: any) {
        return ServiceNoteApiFp(this.configuration).apiServicenotePost(serviceId, evidence, title, note, serviceNoteEvidence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceNoteApi
     */
    public apiServicenoteServiceNoteIdDelete(serviceNoteId: string, options?: any) {
        return ServiceNoteApiFp(this.configuration).apiServicenoteServiceNoteIdDelete(serviceNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceNoteApi
     */
    public apiServicenoteServiceNoteIdGet(serviceNoteId: string, options?: any) {
        return ServiceNoteApiFp(this.configuration).apiServicenoteServiceNoteIdGet(serviceNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceNoteId 
     * @param {ServiceNoteUpdateModel} [serviceNoteUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceNoteApi
     */
    public apiServicenoteServiceNoteIdPut(serviceNoteId: string, serviceNoteUpdateModel?: ServiceNoteUpdateModel, options?: any) {
        return ServiceNoteApiFp(this.configuration).apiServicenoteServiceNoteIdPut(serviceNoteId, serviceNoteUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceNoteApi
     */
    public apiServicenoteServiceServiceIdGet(serviceId: string, options?: any) {
        return ServiceNoteApiFp(this.configuration).apiServicenoteServiceServiceIdGet(serviceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatementApi - axios parameter creator
 * @export
 */
export const StatementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [statementChargeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementChargeDelete: async (statementChargeId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statement/charge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (statementChargeId !== undefined) {
                localVarQueryParameter['statementChargeId'] = statementChargeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementChargePost: async (statementChargeUpsertModel?: StatementChargeUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statement/charge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementChargeUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} statementChargeId 
         * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementChargeStatementChargeIdPut: async (statementChargeId: string, statementChargeUpsertModel?: StatementChargeUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'statementChargeId' is not null or undefined
            assertParamExists('apiStatementChargeStatementChargeIdPut', 'statementChargeId', statementChargeId)
            const localVarPath = `/api/statement/charge/{statementChargeId}`
                .replace(`{${"statementChargeId"}}`, encodeURIComponent(String(statementChargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementChargeUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementVesselEnquiryVesselEnquiryIdGet: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiStatementVesselEnquiryVesselEnquiryIdGet', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/statement/vessel-enquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementVesselEnquiryVesselEnquiryIdPost: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiStatementVesselEnquiryVesselEnquiryIdPost', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/statement/vessel-enquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatementApi - functional programming interface
 * @export
 */
export const StatementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [statementChargeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatementChargeDelete(statementChargeId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatementChargeDelete(statementChargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatementChargePost(statementChargeUpsertModel?: StatementChargeUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatementChargePost(statementChargeUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} statementChargeId 
         * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatementChargeStatementChargeIdPut(statementChargeId: string, statementChargeUpsertModel?: StatementChargeUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatementChargeStatementChargeIdPut(statementChargeId, statementChargeUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatementVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatementVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatementVesselEnquiryVesselEnquiryIdPost(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatementVesselEnquiryVesselEnquiryIdPost(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatementApi - factory interface
 * @export
 */
export const StatementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatementApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [statementChargeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementChargeDelete(statementChargeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiStatementChargeDelete(statementChargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementChargePost(statementChargeUpsertModel?: StatementChargeUpsertModel, options?: any): AxiosPromise<StatementModel> {
            return localVarFp.apiStatementChargePost(statementChargeUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} statementChargeId 
         * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementChargeStatementChargeIdPut(statementChargeId: string, statementChargeUpsertModel?: StatementChargeUpsertModel, options?: any): AxiosPromise<StatementModel> {
            return localVarFp.apiStatementChargeStatementChargeIdPut(statementChargeId, statementChargeUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): AxiosPromise<StatementModel> {
            return localVarFp.apiStatementVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatementVesselEnquiryVesselEnquiryIdPost(vesselEnquiryId: string, options?: any): AxiosPromise<StatementModel> {
            return localVarFp.apiStatementVesselEnquiryVesselEnquiryIdPost(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatementApi - object-oriented interface
 * @export
 * @class StatementApi
 * @extends {BaseAPI}
 */
export class StatementApi extends BaseAPI {
    /**
     * 
     * @param {string} [statementChargeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public apiStatementChargeDelete(statementChargeId?: string, options?: any) {
        return StatementApiFp(this.configuration).apiStatementChargeDelete(statementChargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public apiStatementChargePost(statementChargeUpsertModel?: StatementChargeUpsertModel, options?: any) {
        return StatementApiFp(this.configuration).apiStatementChargePost(statementChargeUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} statementChargeId 
     * @param {StatementChargeUpsertModel} [statementChargeUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public apiStatementChargeStatementChargeIdPut(statementChargeId: string, statementChargeUpsertModel?: StatementChargeUpsertModel, options?: any) {
        return StatementApiFp(this.configuration).apiStatementChargeStatementChargeIdPut(statementChargeId, statementChargeUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public apiStatementVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any) {
        return StatementApiFp(this.configuration).apiStatementVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public apiStatementVesselEnquiryVesselEnquiryIdPost(vesselEnquiryId: string, options?: any) {
        return StatementApiFp(this.configuration).apiStatementVesselEnquiryVesselEnquiryIdPost(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPhotoIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserPhotoIdGet', 'id', id)
            const localVarPath = `/api/user/photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [userId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPhotoPost: async (filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiUserPhotoPost', 'filename', filename)
            const localVarPath = `/api/user/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('UserId', new Blob([JSON.stringify(userId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (documentNumber !== undefined) { 
                localVarFormParams.append('DocumentNumber', documentNumber as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateModel} [userCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (userCreateModel?: UserCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPut: async (userUpdateModel?: UserUpdateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserStaffGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserAdminUpdateModel} [userAdminUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut: async (userId: string, userAdminUpdateModel?: UserAdminUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdPut', 'userId', userId)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAdminUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPhotoIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPhotoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [userId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPhotoPost(filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPhotoPost(filename, userId, document, reference, documentNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCreateModel} [userCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(userCreateModel?: UserCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(userCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPut(userUpdateModel?: UserUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPut(userUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserStaffGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserStaffGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserAdminUpdateModel} [userAdminUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdPut(userId: string, userAdminUpdateModel?: UserAdminUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdPut(userId, userAdminUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPhotoIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserPhotoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [userId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPhotoPost(filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserPhotoPost(filename, userId, document, reference, documentNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreateModel} [userCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(userCreateModel?: UserCreateModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserPost(userCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPut(userUpdateModel?: UserUpdateModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserPut(userUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserStaffGet(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.apiUserStaffGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGet(userId: string, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserAdminUpdateModel} [userAdminUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut(userId: string, userAdminUpdateModel?: UserAdminUpdateModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserUserIdPut(userId, userAdminUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPhotoIdGet(id: string, options?: any) {
        return UserApiFp(this.configuration).apiUserPhotoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [userId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {string} [documentNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPhotoPost(filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserPhotoPost(filename, userId, document, reference, documentNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreateModel} [userCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPost(userCreateModel?: UserCreateModel, options?: any) {
        return UserApiFp(this.configuration).apiUserPost(userCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserUpdateModel} [userUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPut(userUpdateModel?: UserUpdateModel, options?: any) {
        return UserApiFp(this.configuration).apiUserPut(userUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserStaffGet(options?: any) {
        return UserApiFp(this.configuration).apiUserStaffGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdGet(userId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UserAdminUpdateModel} [userAdminUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdPut(userId: string, userAdminUpdateModel?: UserAdminUpdateModel, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdPut(userId, userAdminUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserDocumentApi - axios parameter creator
 * @export
 */
export const UserDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [userId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentPost: async (filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiUserdocumentPost', 'filename', filename)
            const localVarPath = `/api/userdocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('UserId', new Blob([JSON.stringify(userId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (documentNumber !== undefined) { 
                localVarFormParams.append('DocumentNumber', documentNumber as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserDocumentIdDelete: async (userDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDocumentId' is not null or undefined
            assertParamExists('apiUserdocumentUserDocumentIdDelete', 'userDocumentId', userDocumentId)
            const localVarPath = `/api/userdocument/{userDocumentId}`
                .replace(`{${"userDocumentId"}}`, encodeURIComponent(String(userDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserDocumentIdGet: async (userDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDocumentId' is not null or undefined
            assertParamExists('apiUserdocumentUserDocumentIdGet', 'userDocumentId', userDocumentId)
            const localVarPath = `/api/userdocument/{userDocumentId}`
                .replace(`{${"userDocumentId"}}`, encodeURIComponent(String(userDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {UserDocumentUpdateModel} [userDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserDocumentIdPut: async (userDocumentId: string, userDocumentUpdateModel?: UserDocumentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDocumentId' is not null or undefined
            assertParamExists('apiUserdocumentUserDocumentIdPut', 'userDocumentId', userDocumentId)
            const localVarPath = `/api/userdocument/{userDocumentId}`
                .replace(`{${"userDocumentId"}}`, encodeURIComponent(String(userDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDocumentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserdocumentUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/userdocument/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDocumentApi - functional programming interface
 * @export
 */
export const UserDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [userId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserdocumentPost(filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserdocumentPost(filename, userId, document, reference, documentNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserdocumentUserDocumentIdDelete(userDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserdocumentUserDocumentIdDelete(userDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserdocumentUserDocumentIdGet(userDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserdocumentUserDocumentIdGet(userDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {UserDocumentUpdateModel} [userDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserdocumentUserDocumentIdPut(userDocumentId: string, userDocumentUpdateModel?: UserDocumentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserdocumentUserDocumentIdPut(userDocumentId, userDocumentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserdocumentUserUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserdocumentUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDocumentApi - factory interface
 * @export
 */
export const UserDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [userId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentPost(filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options?: any): AxiosPromise<UserDocumentModel> {
            return localVarFp.apiUserdocumentPost(filename, userId, document, reference, documentNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserDocumentIdDelete(userDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserdocumentUserDocumentIdDelete(userDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserDocumentIdGet(userDocumentId: string, options?: any): AxiosPromise<UserDocumentModel> {
            return localVarFp.apiUserdocumentUserDocumentIdGet(userDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userDocumentId 
         * @param {UserDocumentUpdateModel} [userDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserDocumentIdPut(userDocumentId: string, userDocumentUpdateModel?: UserDocumentUpdateModel, options?: any): AxiosPromise<UserDocumentModel> {
            return localVarFp.apiUserdocumentUserDocumentIdPut(userDocumentId, userDocumentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserdocumentUserUserIdGet(userId: string, options?: any): AxiosPromise<Array<UserDocumentModel>> {
            return localVarFp.apiUserdocumentUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserDocumentApi - object-oriented interface
 * @export
 * @class UserDocumentApi
 * @extends {BaseAPI}
 */
export class UserDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {string} [userId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {string} [documentNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocumentApi
     */
    public apiUserdocumentPost(filename: string, userId?: string, document?: any, reference?: string, documentNumber?: string, options?: any) {
        return UserDocumentApiFp(this.configuration).apiUserdocumentPost(filename, userId, document, reference, documentNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocumentApi
     */
    public apiUserdocumentUserDocumentIdDelete(userDocumentId: string, options?: any) {
        return UserDocumentApiFp(this.configuration).apiUserdocumentUserDocumentIdDelete(userDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocumentApi
     */
    public apiUserdocumentUserDocumentIdGet(userDocumentId: string, options?: any) {
        return UserDocumentApiFp(this.configuration).apiUserdocumentUserDocumentIdGet(userDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userDocumentId 
     * @param {UserDocumentUpdateModel} [userDocumentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocumentApi
     */
    public apiUserdocumentUserDocumentIdPut(userDocumentId: string, userDocumentUpdateModel?: UserDocumentUpdateModel, options?: any) {
        return UserDocumentApiFp(this.configuration).apiUserdocumentUserDocumentIdPut(userDocumentId, userDocumentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDocumentApi
     */
    public apiUserdocumentUserUserIdGet(userId: string, options?: any) {
        return UserDocumentApiFp(this.configuration).apiUserdocumentUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserNoteApi - axios parameter creator
 * @export
 */
export const UserNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<UserNoteEvidenceModel>} [userNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernotePost: async (userId?: string, evidence?: Array<any>, title?: string, note?: string, userNoteEvidence?: Array<UserNoteEvidenceModel>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/usernote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('UserId', new Blob([JSON.stringify(userId)], { type: "application/json", }));
            }
                if (evidence) {
                evidence.forEach((element) => {
                    localVarFormParams.append('Evidence', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('Note', note as any);
            }
                if (userNoteEvidence) {
                localVarFormParams.append(userNoteEvidence.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserNoteIdDelete: async (userNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNoteId' is not null or undefined
            assertParamExists('apiUsernoteUserNoteIdDelete', 'userNoteId', userNoteId)
            const localVarPath = `/api/usernote/{userNoteId}`
                .replace(`{${"userNoteId"}}`, encodeURIComponent(String(userNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserNoteIdGet: async (userNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNoteId' is not null or undefined
            assertParamExists('apiUsernoteUserNoteIdGet', 'userNoteId', userNoteId)
            const localVarPath = `/api/usernote/{userNoteId}`
                .replace(`{${"userNoteId"}}`, encodeURIComponent(String(userNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {UserNoteUpdateModel} [userNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserNoteIdPut: async (userNoteId: string, userNoteUpdateModel?: UserNoteUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNoteId' is not null or undefined
            assertParamExists('apiUsernoteUserNoteIdPut', 'userNoteId', userNoteId)
            const localVarPath = `/api/usernote/{userNoteId}`
                .replace(`{${"userNoteId"}}`, encodeURIComponent(String(userNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNoteUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUsernoteUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/usernote/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserNoteApi - functional programming interface
 * @export
 */
export const UserNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<UserNoteEvidenceModel>} [userNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsernotePost(userId?: string, evidence?: Array<any>, title?: string, note?: string, userNoteEvidence?: Array<UserNoteEvidenceModel>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsernotePost(userId, evidence, title, note, userNoteEvidence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsernoteUserNoteIdDelete(userNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsernoteUserNoteIdDelete(userNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsernoteUserNoteIdGet(userNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsernoteUserNoteIdGet(userNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {UserNoteUpdateModel} [userNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsernoteUserNoteIdPut(userNoteId: string, userNoteUpdateModel?: UserNoteUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsernoteUserNoteIdPut(userNoteId, userNoteUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsernoteUserUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsernoteUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserNoteApi - factory interface
 * @export
 */
export const UserNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserNoteApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<UserNoteEvidenceModel>} [userNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernotePost(userId?: string, evidence?: Array<any>, title?: string, note?: string, userNoteEvidence?: Array<UserNoteEvidenceModel>, options?: any): AxiosPromise<UserNoteModel> {
            return localVarFp.apiUsernotePost(userId, evidence, title, note, userNoteEvidence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserNoteIdDelete(userNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsernoteUserNoteIdDelete(userNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserNoteIdGet(userNoteId: string, options?: any): AxiosPromise<UserNoteModel> {
            return localVarFp.apiUsernoteUserNoteIdGet(userNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userNoteId 
         * @param {UserNoteUpdateModel} [userNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserNoteIdPut(userNoteId: string, userNoteUpdateModel?: UserNoteUpdateModel, options?: any): AxiosPromise<UserNoteModel> {
            return localVarFp.apiUsernoteUserNoteIdPut(userNoteId, userNoteUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsernoteUserUserIdGet(userId: string, options?: any): AxiosPromise<Array<UserNoteModel>> {
            return localVarFp.apiUsernoteUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserNoteApi - object-oriented interface
 * @export
 * @class UserNoteApi
 * @extends {BaseAPI}
 */
export class UserNoteApi extends BaseAPI {
    /**
     * 
     * @param {string} [userId] 
     * @param {Array<any>} [evidence] 
     * @param {string} [title] 
     * @param {string} [note] 
     * @param {Array<UserNoteEvidenceModel>} [userNoteEvidence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNoteApi
     */
    public apiUsernotePost(userId?: string, evidence?: Array<any>, title?: string, note?: string, userNoteEvidence?: Array<UserNoteEvidenceModel>, options?: any) {
        return UserNoteApiFp(this.configuration).apiUsernotePost(userId, evidence, title, note, userNoteEvidence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNoteApi
     */
    public apiUsernoteUserNoteIdDelete(userNoteId: string, options?: any) {
        return UserNoteApiFp(this.configuration).apiUsernoteUserNoteIdDelete(userNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNoteApi
     */
    public apiUsernoteUserNoteIdGet(userNoteId: string, options?: any) {
        return UserNoteApiFp(this.configuration).apiUsernoteUserNoteIdGet(userNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userNoteId 
     * @param {UserNoteUpdateModel} [userNoteUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNoteApi
     */
    public apiUsernoteUserNoteIdPut(userNoteId: string, userNoteUpdateModel?: UserNoteUpdateModel, options?: any) {
        return UserNoteApiFp(this.configuration).apiUsernoteUserNoteIdPut(userNoteId, userNoteUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNoteApi
     */
    public apiUsernoteUserUserIdGet(userId: string, options?: any) {
        return UserNoteApiFp(this.configuration).apiUsernoteUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VesselApi - axios parameter creator
 * @export
 */
export const VesselApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencySponsoredUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselByagencySponsoredUserIdGet', 'userId', userId)
            const localVarPath = `/api/vessel/byagency/sponsored/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencyWithSponsoredUserIdDiscriminatorGet: async (userId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselByagencyWithSponsoredUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselByagencyWithSponsoredUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vessel/byagency/with-sponsored/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencyWithSponsoredUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselByagencyWithSponsoredUserIdGet', 'userId', userId)
            const localVarPath = `/api/vessel/byagency/with-sponsored/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencyWithoutSponsoredUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselByagencyWithoutSponsoredUserIdGet', 'userId', userId)
            const localVarPath = `/api/vessel/byagency/without-sponsored/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vessel/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselDonotuseGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vessel/donotuse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselDonotuseUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselDonotuseUserIdGet', 'userId', userId)
            const localVarPath = `/api/vessel/donotuse/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselExportSearchResultGet: async (searchResult: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchResult' is not null or undefined
            assertParamExists('apiVesselExportSearchResultGet', 'searchResult', searchResult)
            const localVarPath = `/api/vessel/export/{searchResult}`
                .replace(`{${"searchResult"}}`, encodeURIComponent(String(searchResult)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselExportWithContractsSearchResultGet: async (searchResult: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchResult' is not null or undefined
            assertParamExists('apiVesselExportWithContractsSearchResultGet', 'searchResult', searchResult)
            const localVarPath = `/api/vessel/export-with-contracts/{searchResult}`
                .replace(`{${"searchResult"}}`, encodeURIComponent(String(searchResult)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselExportWithoutContractsSearchResultGet: async (searchResult: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchResult' is not null or undefined
            assertParamExists('apiVesselExportWithoutContractsSearchResultGet', 'searchResult', searchResult)
            const localVarPath = `/api/vessel/export-without-contracts/{searchResult}`
                .replace(`{${"searchResult"}}`, encodeURIComponent(String(searchResult)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vessel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselPhotoIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVesselPhotoIdGet', 'id', id)
            const localVarPath = `/api/vessel/photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselPhotoPost: async (filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiVesselPhotoPost', 'filename', filename)
            const localVarPath = `/api/vessel/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (vesselId !== undefined) { 
                localVarFormParams.append('VesselId', new Blob([JSON.stringify(vesselId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (documentNumber !== undefined) { 
                localVarFormParams.append('DocumentNumber', documentNumber as any);
            }
    
            if (startDate !== undefined) { 
                localVarFormParams.append('StartDate', startDate as any);
            }
    
            if (endDate !== undefined) { 
                localVarFormParams.append('EndDate', endDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VesselUpsertModel} [vesselUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselPost: async (vesselUpsertModel?: VesselUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vessel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselSponsoredGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vessel/sponsored`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselVesselIdDelete: async (vesselId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('apiVesselVesselIdDelete', 'vesselId', vesselId)
            const localVarPath = `/api/vessel/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselVesselIdGet: async (vesselId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('apiVesselVesselIdGet', 'vesselId', vesselId)
            const localVarPath = `/api/vessel/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {VesselUpsertModel} [vesselUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselVesselIdPut: async (vesselId: string, vesselUpsertModel?: VesselUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('apiVesselVesselIdPut', 'vesselId', vesselId)
            const localVarPath = `/api/vessel/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselWithSponsoredGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vessel/with-sponsored`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselApi - functional programming interface
 * @export
 */
export const VesselApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselByagencySponsoredUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselByagencySponsoredUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselByagencyWithSponsoredUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselByagencyWithSponsoredUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselByagencyWithoutSponsoredUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselByagencyWithoutSponsoredUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselDonotuseGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselDonotuseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselDonotuseUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselDonotuseUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselExportSearchResultGet(searchResult: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselExportSearchResultGet(searchResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselExportWithContractsSearchResultGet(searchResult: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselExportWithContractsSearchResultGet(searchResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselExportWithoutContractsSearchResultGet(searchResult: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselExportWithoutContractsSearchResultGet(searchResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselPhotoIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselPhotoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselPhotoPost(filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselPhotoPost(filename, vesselId, document, reference, documentNumber, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VesselUpsertModel} [vesselUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselPost(vesselUpsertModel?: VesselUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselPost(vesselUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselSponsoredGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselSponsoredGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselVesselIdDelete(vesselId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselVesselIdDelete(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselVesselIdGet(vesselId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselVesselIdGet(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {VesselUpsertModel} [vesselUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselVesselIdPut(vesselId: string, vesselUpsertModel?: VesselUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselVesselIdPut(vesselId, vesselUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselWithSponsoredGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselWithSponsoredGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselApi - factory interface
 * @export
 */
export const VesselApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencySponsoredUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselByagencySponsoredUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencyWithSponsoredUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselByagencyWithSponsoredUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselByagencyWithoutSponsoredUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselByagencyWithoutSponsoredUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselDonotuseGet(options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselDonotuseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselDonotuseUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselDonotuseUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselExportSearchResultGet(searchResult: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiVesselExportSearchResultGet(searchResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselExportWithContractsSearchResultGet(searchResult: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiVesselExportWithContractsSearchResultGet(searchResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselExportWithoutContractsSearchResultGet(searchResult: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiVesselExportWithoutContractsSearchResultGet(searchResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselGet(options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselPhotoIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesselPhotoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselPhotoPost(filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiVesselPhotoPost(filename, vesselId, document, reference, documentNumber, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VesselUpsertModel} [vesselUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselPost(vesselUpsertModel?: VesselUpsertModel, options?: any): AxiosPromise<VesselModel> {
            return localVarFp.apiVesselPost(vesselUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselSponsoredGet(options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselSponsoredGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselVesselIdDelete(vesselId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesselVesselIdDelete(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselVesselIdGet(vesselId: string, options?: any): AxiosPromise<VesselModel> {
            return localVarFp.apiVesselVesselIdGet(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {VesselUpsertModel} [vesselUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselVesselIdPut(vesselId: string, vesselUpsertModel?: VesselUpsertModel, options?: any): AxiosPromise<VesselModel> {
            return localVarFp.apiVesselVesselIdPut(vesselId, vesselUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselWithSponsoredGet(options?: any): AxiosPromise<Array<VesselModel>> {
            return localVarFp.apiVesselWithSponsoredGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselApi - object-oriented interface
 * @export
 * @class VesselApi
 * @extends {BaseAPI}
 */
export class VesselApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselByagencySponsoredUserIdGet(userId: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselByagencySponsoredUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any) {
        return VesselApiFp(this.configuration).apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselByagencyWithSponsoredUserIdGet(userId: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselByagencyWithSponsoredUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselByagencyWithoutSponsoredUserIdGet(userId: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselByagencyWithoutSponsoredUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselDiscriminatorGet(discriminator: ContractType, options?: any) {
        return VesselApiFp(this.configuration).apiVesselDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselDonotuseGet(options?: any) {
        return VesselApiFp(this.configuration).apiVesselDonotuseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselDonotuseUserIdGet(userId: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselDonotuseUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselExportSearchResultGet(searchResult: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselExportSearchResultGet(searchResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselExportWithContractsSearchResultGet(searchResult: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselExportWithContractsSearchResultGet(searchResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselExportWithoutContractsSearchResultGet(searchResult: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselExportWithoutContractsSearchResultGet(searchResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselGet(options?: any) {
        return VesselApiFp(this.configuration).apiVesselGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselPhotoIdGet(id: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselPhotoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [vesselId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {string} [documentNumber] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselPhotoPost(filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselPhotoPost(filename, vesselId, document, reference, documentNumber, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VesselUpsertModel} [vesselUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselPost(vesselUpsertModel?: VesselUpsertModel, options?: any) {
        return VesselApiFp(this.configuration).apiVesselPost(vesselUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselSponsoredGet(options?: any) {
        return VesselApiFp(this.configuration).apiVesselSponsoredGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselVesselIdDelete(vesselId: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselVesselIdDelete(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselVesselIdGet(vesselId: string, options?: any) {
        return VesselApiFp(this.configuration).apiVesselVesselIdGet(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselId 
     * @param {VesselUpsertModel} [vesselUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselVesselIdPut(vesselId: string, vesselUpsertModel?: VesselUpsertModel, options?: any) {
        return VesselApiFp(this.configuration).apiVesselVesselIdPut(vesselId, vesselUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public apiVesselWithSponsoredGet(options?: any) {
        return VesselApiFp(this.configuration).apiVesselWithSponsoredGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VesselDocumentApi - axios parameter creator
 * @export
 */
export const VesselDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {boolean} archiveStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut: async (vesselDocumentId: string, archiveStatus: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselDocumentId' is not null or undefined
            assertParamExists('apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut', 'vesselDocumentId', vesselDocumentId)
            // verify required parameter 'archiveStatus' is not null or undefined
            assertParamExists('apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut', 'archiveStatus', archiveStatus)
            const localVarPath = `/api/vesseldocument/archive/{vesselDocumentId}/{archiveStatus}`
                .replace(`{${"vesselDocumentId"}}`, encodeURIComponent(String(vesselDocumentId)))
                .replace(`{${"archiveStatus"}}`, encodeURIComponent(String(archiveStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentExpiringGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesseldocument/expiring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentPost: async (filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiVesseldocumentPost', 'filename', filename)
            const localVarPath = `/api/vesseldocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (vesselId !== undefined) { 
                localVarFormParams.append('VesselId', new Blob([JSON.stringify(vesselId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
            if (documentNumber !== undefined) { 
                localVarFormParams.append('DocumentNumber', documentNumber as any);
            }
    
            if (startDate !== undefined) { 
                localVarFormParams.append('StartDate', startDate as any);
            }
    
            if (endDate !== undefined) { 
                localVarFormParams.append('EndDate', endDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselAgencyVesselIdGet: async (vesselId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('apiVesseldocumentVesselAgencyVesselIdGet', 'vesselId', vesselId)
            const localVarPath = `/api/vesseldocument/vessel/agency/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselDocumentIdDelete: async (vesselDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselDocumentId' is not null or undefined
            assertParamExists('apiVesseldocumentVesselDocumentIdDelete', 'vesselDocumentId', vesselDocumentId)
            const localVarPath = `/api/vesseldocument/{vesselDocumentId}`
                .replace(`{${"vesselDocumentId"}}`, encodeURIComponent(String(vesselDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselDocumentIdGet: async (vesselDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselDocumentId' is not null or undefined
            assertParamExists('apiVesseldocumentVesselDocumentIdGet', 'vesselDocumentId', vesselDocumentId)
            const localVarPath = `/api/vesseldocument/{vesselDocumentId}`
                .replace(`{${"vesselDocumentId"}}`, encodeURIComponent(String(vesselDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {VesselDocumentUpdateModel} [vesselDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselDocumentIdPut: async (vesselDocumentId: string, vesselDocumentUpdateModel?: VesselDocumentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselDocumentId' is not null or undefined
            assertParamExists('apiVesseldocumentVesselDocumentIdPut', 'vesselDocumentId', vesselDocumentId)
            const localVarPath = `/api/vesseldocument/{vesselDocumentId}`
                .replace(`{${"vesselDocumentId"}}`, encodeURIComponent(String(vesselDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselDocumentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselVesselIdGet: async (vesselId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('apiVesseldocumentVesselVesselIdGet', 'vesselId', vesselId)
            const localVarPath = `/api/vesseldocument/vessel/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselDocumentApi - functional programming interface
 * @export
 */
export const VesselDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {boolean} archiveStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(vesselDocumentId: string, archiveStatus: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(vesselDocumentId, archiveStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentExpiringGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentExpiringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentPost(filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentPost(filename, vesselId, document, reference, documentNumber, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentVesselAgencyVesselIdGet(vesselId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentVesselAgencyVesselIdGet(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentVesselDocumentIdDelete(vesselDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentVesselDocumentIdDelete(vesselDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentVesselDocumentIdGet(vesselDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentVesselDocumentIdGet(vesselDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {VesselDocumentUpdateModel} [vesselDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentVesselDocumentIdPut(vesselDocumentId: string, vesselDocumentUpdateModel?: VesselDocumentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentVesselDocumentIdPut(vesselDocumentId, vesselDocumentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseldocumentVesselVesselIdGet(vesselId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseldocumentVesselVesselIdGet(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselDocumentApi - factory interface
 * @export
 */
export const VesselDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {boolean} archiveStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(vesselDocumentId: string, archiveStatus: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(vesselDocumentId, archiveStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentExpiringGet(options?: any): AxiosPromise<Array<VesselDocumentModel>> {
            return localVarFp.apiVesseldocumentExpiringGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {string} [documentNumber] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentPost(filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<VesselDocumentModel> {
            return localVarFp.apiVesseldocumentPost(filename, vesselId, document, reference, documentNumber, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselAgencyVesselIdGet(vesselId: string, options?: any): AxiosPromise<Array<VesselDocumentModel>> {
            return localVarFp.apiVesseldocumentVesselAgencyVesselIdGet(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselDocumentIdDelete(vesselDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesseldocumentVesselDocumentIdDelete(vesselDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselDocumentIdGet(vesselDocumentId: string, options?: any): AxiosPromise<VesselDocumentModel> {
            return localVarFp.apiVesseldocumentVesselDocumentIdGet(vesselDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselDocumentId 
         * @param {VesselDocumentUpdateModel} [vesselDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselDocumentIdPut(vesselDocumentId: string, vesselDocumentUpdateModel?: VesselDocumentUpdateModel, options?: any): AxiosPromise<VesselDocumentModel> {
            return localVarFp.apiVesseldocumentVesselDocumentIdPut(vesselDocumentId, vesselDocumentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseldocumentVesselVesselIdGet(vesselId: string, options?: any): AxiosPromise<Array<VesselDocumentModel>> {
            return localVarFp.apiVesseldocumentVesselVesselIdGet(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselDocumentApi - object-oriented interface
 * @export
 * @class VesselDocumentApi
 * @extends {BaseAPI}
 */
export class VesselDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} vesselDocumentId 
     * @param {boolean} archiveStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(vesselDocumentId: string, archiveStatus: boolean, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(vesselDocumentId, archiveStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentExpiringGet(options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentExpiringGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [vesselId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {string} [documentNumber] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentPost(filename: string, vesselId?: string, document?: any, reference?: string, documentNumber?: string, startDate?: string, endDate?: string, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentPost(filename, vesselId, document, reference, documentNumber, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentVesselAgencyVesselIdGet(vesselId: string, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentVesselAgencyVesselIdGet(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentVesselDocumentIdDelete(vesselDocumentId: string, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentVesselDocumentIdDelete(vesselDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentVesselDocumentIdGet(vesselDocumentId: string, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentVesselDocumentIdGet(vesselDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselDocumentId 
     * @param {VesselDocumentUpdateModel} [vesselDocumentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentVesselDocumentIdPut(vesselDocumentId: string, vesselDocumentUpdateModel?: VesselDocumentUpdateModel, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentVesselDocumentIdPut(vesselDocumentId, vesselDocumentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselDocumentApi
     */
    public apiVesseldocumentVesselVesselIdGet(vesselId: string, options?: any) {
        return VesselDocumentApiFp(this.configuration).apiVesseldocumentVesselVesselIdGet(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VesselEnquiryApi - axios parameter creator
 * @export
 */
export const VesselEnquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryActiveDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/active/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveUserIdDiscriminatorGet: async (userId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryActiveUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryActiveUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/active/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryActiveUserIdGet', 'userId', userId)
            const localVarPath = `/api/vesselenquiry/active/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedContractTypeGet: async (contractType: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractType' is not null or undefined
            assertParamExists('apiVesselenquiryClosedContractTypeGet', 'contractType', contractType)
            const localVarPath = `/api/vesselenquiry/closed/{contractType}`
                .replace(`{${"contractType"}}`, encodeURIComponent(String(contractType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry/closed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedUserIdDiscriminatorGet: async (userId: string, discriminator: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryClosedUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryClosedUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/closed/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryClosedUserIdGet', 'userId', userId)
            const localVarPath = `/api/vesselenquiry/closed/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet: async (crewMemberId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet', 'crewMemberId', crewMemberId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/crewmemberpastcontracts/{crewMemberId}/{discriminator}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet: async (crewMemberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crewMemberId' is not null or undefined
            assertParamExists('apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet', 'crewMemberId', crewMemberId)
            const localVarPath = `/api/vesselenquiry/crewmemberpastcontracts/{crewMemberId}`
                .replace(`{${"crewMemberId"}}`, encodeURIComponent(String(crewMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredContractTypeGet: async (contractType: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractType' is not null or undefined
            assertParamExists('apiVesselenquiryExpiredContractTypeGet', 'contractType', contractType)
            const localVarPath = `/api/vesselenquiry/expired/{contractType}`
                .replace(`{${"contractType"}}`, encodeURIComponent(String(contractType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry/expired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredUserIdDiscriminatorGet: async (userId: string, discriminator: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryExpiredUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryExpiredUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/expired/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryExpiredUserIdGet', 'userId', userId)
            const localVarPath = `/api/vesselenquiry/expired/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringContractTypeGet: async (contractType: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractType' is not null or undefined
            assertParamExists('apiVesselenquiryExpiringContractTypeGet', 'contractType', contractType)
            const localVarPath = `/api/vesselenquiry/expiring/{contractType}`
                .replace(`{${"contractType"}}`, encodeURIComponent(String(contractType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry/expiring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringUserIdDiscriminatorGet: async (userId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryExpiringUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryExpiringUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/expiring/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryExpiringUserIdGet', 'userId', userId)
            const localVarPath = `/api/vesselenquiry/expiring/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialContractTypeGet: async (contractType: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractType' is not null or undefined
            assertParamExists('apiVesselenquiryInitialContractTypeGet', 'contractType', contractType)
            const localVarPath = `/api/vesselenquiry/initial/{contractType}`
                .replace(`{${"contractType"}}`, encodeURIComponent(String(contractType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry/initial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialUserIdDiscriminatorGet: async (userId: string, discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryInitialUserIdDiscriminatorGet', 'userId', userId)
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesselenquiryInitialUserIdDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesselenquiry/initial/{userId}/{discriminator}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiVesselenquiryInitialUserIdGet', 'userId', userId)
            const localVarPath = `/api/vesselenquiry/initial/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryOpenGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VesselEnquiryCreateModel} [vesselEnquiryCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryPost: async (vesselEnquiryCreateModel?: VesselEnquiryCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselenquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselEnquiryCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryVesselEnquiryIdDelete: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiVesselenquiryVesselEnquiryIdDelete', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/vesselenquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryVesselEnquiryIdGet: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiVesselenquiryVesselEnquiryIdGet', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/vesselenquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {VesselEnquiryUpdateModel} [vesselEnquiryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryVesselEnquiryIdPut: async (vesselEnquiryId: string, vesselEnquiryUpdateModel?: VesselEnquiryUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiVesselenquiryVesselEnquiryIdPut', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/vesselenquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselEnquiryUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselEnquiryApi - functional programming interface
 * @export
 */
export const VesselEnquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselEnquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryActiveDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryActiveDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryActiveGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryActiveGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryActiveUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryActiveUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryActiveUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryActiveUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryClosedContractTypeGet(contractType: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryClosedContractTypeGet(contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryClosedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryClosedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryClosedUserIdDiscriminatorGet(userId: string, discriminator: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryClosedUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryClosedUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryClosedUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet(crewMemberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet(crewMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiredContractTypeGet(contractType: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiredContractTypeGet(contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiredGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiredGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiredUserIdDiscriminatorGet(userId: string, discriminator: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiredUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiredUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiredUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiringContractTypeGet(contractType: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiringContractTypeGet(contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiringGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiringUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiringUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryExpiringUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryExpiringUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryInitialContractTypeGet(contractType: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryInitialContractTypeGet(contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryInitialGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryInitialGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryInitialUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryInitialUserIdDiscriminatorGet(userId, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryInitialUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryInitialUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryOpenGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryOpenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VesselEnquiryCreateModel} [vesselEnquiryCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryPost(vesselEnquiryCreateModel?: VesselEnquiryCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryPost(vesselEnquiryCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryVesselEnquiryIdDelete(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryVesselEnquiryIdDelete(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryVesselEnquiryIdGet(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {VesselEnquiryUpdateModel} [vesselEnquiryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquiryVesselEnquiryIdPut(vesselEnquiryId: string, vesselEnquiryUpdateModel?: VesselEnquiryUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselEnquiryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquiryVesselEnquiryIdPut(vesselEnquiryId, vesselEnquiryUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselEnquiryApi - factory interface
 * @export
 */
export const VesselEnquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselEnquiryApiFp(configuration)
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryActiveDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryActiveGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryActiveUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryActiveUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryActiveUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedContractTypeGet(contractType: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryClosedContractTypeGet(contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryClosedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedUserIdDiscriminatorGet(userId: string, discriminator: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryClosedUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryClosedUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryClosedUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} crewMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet(crewMemberId: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet(crewMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredContractTypeGet(contractType: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiredContractTypeGet(contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiredGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredUserIdDiscriminatorGet(userId: string, discriminator: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiredUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiredUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiredUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringContractTypeGet(contractType: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiringContractTypeGet(contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiringGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiringUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryExpiringUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryExpiringUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractType} contractType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialContractTypeGet(contractType: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryInitialContractTypeGet(contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryInitialGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryInitialUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryInitialUserIdGet(userId: string, options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryInitialUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryOpenGet(options?: any): AxiosPromise<Array<VesselEnquiryModel>> {
            return localVarFp.apiVesselenquiryOpenGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VesselEnquiryCreateModel} [vesselEnquiryCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryPost(vesselEnquiryCreateModel?: VesselEnquiryCreateModel, options?: any): AxiosPromise<VesselEnquiryModel> {
            return localVarFp.apiVesselenquiryPost(vesselEnquiryCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryVesselEnquiryIdDelete(vesselEnquiryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesselenquiryVesselEnquiryIdDelete(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): AxiosPromise<VesselEnquiryModel> {
            return localVarFp.apiVesselenquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {VesselEnquiryUpdateModel} [vesselEnquiryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquiryVesselEnquiryIdPut(vesselEnquiryId: string, vesselEnquiryUpdateModel?: VesselEnquiryUpdateModel, options?: any): AxiosPromise<VesselEnquiryModel> {
            return localVarFp.apiVesselenquiryVesselEnquiryIdPut(vesselEnquiryId, vesselEnquiryUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselEnquiryApi - object-oriented interface
 * @export
 * @class VesselEnquiryApi
 * @extends {BaseAPI}
 */
export class VesselEnquiryApi extends BaseAPI {
    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryActiveDiscriminatorGet(discriminator: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryActiveDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryActiveGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryActiveGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryActiveUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryActiveUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryActiveUserIdGet(userId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryActiveUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} contractType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryClosedContractTypeGet(contractType: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryClosedContractTypeGet(contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryClosedGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryClosedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryClosedUserIdDiscriminatorGet(userId: string, discriminator: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryClosedUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryClosedUserIdGet(userId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryClosedUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(crewMemberId: string, discriminator: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(crewMemberId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} crewMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet(crewMemberId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryCrewmemberpastcontractsCrewMemberIdGet(crewMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} contractType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiredContractTypeGet(contractType: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiredContractTypeGet(contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiredGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiredGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiredUserIdDiscriminatorGet(userId: string, discriminator: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiredUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiredUserIdGet(userId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiredUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} contractType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiringContractTypeGet(contractType: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiringContractTypeGet(contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiringGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiringGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiringUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiringUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryExpiringUserIdGet(userId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryExpiringUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractType} contractType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryInitialContractTypeGet(contractType: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryInitialContractTypeGet(contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryInitialGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryInitialGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryInitialUserIdDiscriminatorGet(userId: string, discriminator: ContractType, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryInitialUserIdDiscriminatorGet(userId, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryInitialUserIdGet(userId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryInitialUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryOpenGet(options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryOpenGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VesselEnquiryCreateModel} [vesselEnquiryCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryPost(vesselEnquiryCreateModel?: VesselEnquiryCreateModel, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryPost(vesselEnquiryCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryVesselEnquiryIdDelete(vesselEnquiryId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryVesselEnquiryIdDelete(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {VesselEnquiryUpdateModel} [vesselEnquiryUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryApi
     */
    public apiVesselenquiryVesselEnquiryIdPut(vesselEnquiryId: string, vesselEnquiryUpdateModel?: VesselEnquiryUpdateModel, options?: any) {
        return VesselEnquiryApiFp(this.configuration).apiVesselenquiryVesselEnquiryIdPut(vesselEnquiryId, vesselEnquiryUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VesselEnquiryDocumentApi - axios parameter creator
 * @export
 */
export const VesselEnquiryDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselEnquiryId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentPost: async (filename: string, vesselEnquiryId?: string, document?: any, reference?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiVesselenquirydocumentPost', 'filename', filename)
            const localVarPath = `/api/vesselenquirydocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (vesselEnquiryId !== undefined) { 
                localVarFormParams.append('VesselEnquiryId', new Blob([JSON.stringify(vesselEnquiryId)], { type: "application/json", }));
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('Document', document as any);
            }
    
            if (reference !== undefined) { 
                localVarFormParams.append('Reference', reference as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('Filename', filename as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryDocumentIdDelete: async (vesselEnquiryDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryDocumentId' is not null or undefined
            assertParamExists('apiVesselenquirydocumentVesselEnquiryDocumentIdDelete', 'vesselEnquiryDocumentId', vesselEnquiryDocumentId)
            const localVarPath = `/api/vesselenquirydocument/{vesselEnquiryDocumentId}`
                .replace(`{${"vesselEnquiryDocumentId"}}`, encodeURIComponent(String(vesselEnquiryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryDocumentIdGet: async (vesselEnquiryDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryDocumentId' is not null or undefined
            assertParamExists('apiVesselenquirydocumentVesselEnquiryDocumentIdGet', 'vesselEnquiryDocumentId', vesselEnquiryDocumentId)
            const localVarPath = `/api/vesselenquirydocument/{vesselEnquiryDocumentId}`
                .replace(`{${"vesselEnquiryDocumentId"}}`, encodeURIComponent(String(vesselEnquiryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {VesselEnquiryDocumentUpdateModel} [vesselEnquiryDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryDocumentIdPut: async (vesselEnquiryDocumentId: string, vesselEnquiryDocumentUpdateModel?: VesselEnquiryDocumentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryDocumentId' is not null or undefined
            assertParamExists('apiVesselenquirydocumentVesselEnquiryDocumentIdPut', 'vesselEnquiryDocumentId', vesselEnquiryDocumentId)
            const localVarPath = `/api/vesselenquirydocument/{vesselEnquiryDocumentId}`
                .replace(`{${"vesselEnquiryDocumentId"}}`, encodeURIComponent(String(vesselEnquiryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselEnquiryDocumentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet: async (vesselEnquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselEnquiryId' is not null or undefined
            assertParamExists('apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet', 'vesselEnquiryId', vesselEnquiryId)
            const localVarPath = `/api/vesselenquirydocument/vessel-enquiry/{vesselEnquiryId}`
                .replace(`{${"vesselEnquiryId"}}`, encodeURIComponent(String(vesselEnquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselEnquiryDocumentApi - functional programming interface
 * @export
 */
export const VesselEnquiryDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselEnquiryDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselEnquiryId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquirydocumentPost(filename: string, vesselEnquiryId?: string, document?: any, reference?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselEnquiryDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquirydocumentPost(filename, vesselEnquiryId, document, reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(vesselEnquiryDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(vesselEnquiryDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquirydocumentVesselEnquiryDocumentIdGet(vesselEnquiryDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselEnquiryDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquirydocumentVesselEnquiryDocumentIdGet(vesselEnquiryDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {VesselEnquiryDocumentUpdateModel} [vesselEnquiryDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquirydocumentVesselEnquiryDocumentIdPut(vesselEnquiryDocumentId: string, vesselEnquiryDocumentUpdateModel?: VesselEnquiryDocumentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselEnquiryDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquirydocumentVesselEnquiryDocumentIdPut(vesselEnquiryDocumentId, vesselEnquiryDocumentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselEnquiryDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselEnquiryDocumentApi - factory interface
 * @export
 */
export const VesselEnquiryDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselEnquiryDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} [vesselEnquiryId] 
         * @param {any} [document] 
         * @param {string} [reference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentPost(filename: string, vesselEnquiryId?: string, document?: any, reference?: string, options?: any): AxiosPromise<VesselEnquiryDocumentModel> {
            return localVarFp.apiVesselenquirydocumentPost(filename, vesselEnquiryId, document, reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(vesselEnquiryDocumentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(vesselEnquiryDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryDocumentIdGet(vesselEnquiryDocumentId: string, options?: any): AxiosPromise<VesselEnquiryDocumentModel> {
            return localVarFp.apiVesselenquirydocumentVesselEnquiryDocumentIdGet(vesselEnquiryDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryDocumentId 
         * @param {VesselEnquiryDocumentUpdateModel} [vesselEnquiryDocumentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryDocumentIdPut(vesselEnquiryDocumentId: string, vesselEnquiryDocumentUpdateModel?: VesselEnquiryDocumentUpdateModel, options?: any): AxiosPromise<VesselEnquiryDocumentModel> {
            return localVarFp.apiVesselenquirydocumentVesselEnquiryDocumentIdPut(vesselEnquiryDocumentId, vesselEnquiryDocumentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselEnquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any): AxiosPromise<Array<VesselEnquiryDocumentModel>> {
            return localVarFp.apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselEnquiryDocumentApi - object-oriented interface
 * @export
 * @class VesselEnquiryDocumentApi
 * @extends {BaseAPI}
 */
export class VesselEnquiryDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {string} [vesselEnquiryId] 
     * @param {any} [document] 
     * @param {string} [reference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryDocumentApi
     */
    public apiVesselenquirydocumentPost(filename: string, vesselEnquiryId?: string, document?: any, reference?: string, options?: any) {
        return VesselEnquiryDocumentApiFp(this.configuration).apiVesselenquirydocumentPost(filename, vesselEnquiryId, document, reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryDocumentApi
     */
    public apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(vesselEnquiryDocumentId: string, options?: any) {
        return VesselEnquiryDocumentApiFp(this.configuration).apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(vesselEnquiryDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryDocumentApi
     */
    public apiVesselenquirydocumentVesselEnquiryDocumentIdGet(vesselEnquiryDocumentId: string, options?: any) {
        return VesselEnquiryDocumentApiFp(this.configuration).apiVesselenquirydocumentVesselEnquiryDocumentIdGet(vesselEnquiryDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryDocumentId 
     * @param {VesselEnquiryDocumentUpdateModel} [vesselEnquiryDocumentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryDocumentApi
     */
    public apiVesselenquirydocumentVesselEnquiryDocumentIdPut(vesselEnquiryDocumentId: string, vesselEnquiryDocumentUpdateModel?: VesselEnquiryDocumentUpdateModel, options?: any) {
        return VesselEnquiryDocumentApiFp(this.configuration).apiVesselenquirydocumentVesselEnquiryDocumentIdPut(vesselEnquiryDocumentId, vesselEnquiryDocumentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselEnquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselEnquiryDocumentApi
     */
    public apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId: string, options?: any) {
        return VesselEnquiryDocumentApiFp(this.configuration).apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(vesselEnquiryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VesselNoteApi - axios parameter creator
 * @export
 */
export const VesselNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [vesselId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<VesselNoteEvidenceModel>} [vesselNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnotePost: async (vesselId?: string, evidence?: Array<any>, title?: string, note?: string, vesselNoteEvidence?: Array<VesselNoteEvidenceModel>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesselnote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (vesselId !== undefined) { 
                localVarFormParams.append('VesselId', new Blob([JSON.stringify(vesselId)], { type: "application/json", }));
            }
                if (evidence) {
                evidence.forEach((element) => {
                    localVarFormParams.append('Evidence', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('Note', note as any);
            }
                if (vesselNoteEvidence) {
                localVarFormParams.append(vesselNoteEvidence.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselNoteIdDelete: async (vesselNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselNoteId' is not null or undefined
            assertParamExists('apiVesselnoteVesselNoteIdDelete', 'vesselNoteId', vesselNoteId)
            const localVarPath = `/api/vesselnote/{vesselNoteId}`
                .replace(`{${"vesselNoteId"}}`, encodeURIComponent(String(vesselNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselNoteIdGet: async (vesselNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselNoteId' is not null or undefined
            assertParamExists('apiVesselnoteVesselNoteIdGet', 'vesselNoteId', vesselNoteId)
            const localVarPath = `/api/vesselnote/{vesselNoteId}`
                .replace(`{${"vesselNoteId"}}`, encodeURIComponent(String(vesselNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {VesselNoteUpdateModel} [vesselNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselNoteIdPut: async (vesselNoteId: string, vesselNoteUpdateModel?: VesselNoteUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselNoteId' is not null or undefined
            assertParamExists('apiVesselnoteVesselNoteIdPut', 'vesselNoteId', vesselNoteId)
            const localVarPath = `/api/vesselnote/{vesselNoteId}`
                .replace(`{${"vesselNoteId"}}`, encodeURIComponent(String(vesselNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselNoteUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselVesselIdGet: async (vesselId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('apiVesselnoteVesselVesselIdGet', 'vesselId', vesselId)
            const localVarPath = `/api/vesselnote/vessel/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselNoteApi - functional programming interface
 * @export
 */
export const VesselNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [vesselId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<VesselNoteEvidenceModel>} [vesselNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselnotePost(vesselId?: string, evidence?: Array<any>, title?: string, note?: string, vesselNoteEvidence?: Array<VesselNoteEvidenceModel>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselnotePost(vesselId, evidence, title, note, vesselNoteEvidence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselnoteVesselNoteIdDelete(vesselNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselnoteVesselNoteIdDelete(vesselNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselnoteVesselNoteIdGet(vesselNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselnoteVesselNoteIdGet(vesselNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {VesselNoteUpdateModel} [vesselNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselnoteVesselNoteIdPut(vesselNoteId: string, vesselNoteUpdateModel?: VesselNoteUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselNoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselnoteVesselNoteIdPut(vesselNoteId, vesselNoteUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesselnoteVesselVesselIdGet(vesselId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselNoteModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesselnoteVesselVesselIdGet(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselNoteApi - factory interface
 * @export
 */
export const VesselNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselNoteApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [vesselId] 
         * @param {Array<any>} [evidence] 
         * @param {string} [title] 
         * @param {string} [note] 
         * @param {Array<VesselNoteEvidenceModel>} [vesselNoteEvidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnotePost(vesselId?: string, evidence?: Array<any>, title?: string, note?: string, vesselNoteEvidence?: Array<VesselNoteEvidenceModel>, options?: any): AxiosPromise<VesselNoteModel> {
            return localVarFp.apiVesselnotePost(vesselId, evidence, title, note, vesselNoteEvidence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselNoteIdDelete(vesselNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesselnoteVesselNoteIdDelete(vesselNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselNoteIdGet(vesselNoteId: string, options?: any): AxiosPromise<VesselNoteModel> {
            return localVarFp.apiVesselnoteVesselNoteIdGet(vesselNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselNoteId 
         * @param {VesselNoteUpdateModel} [vesselNoteUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselNoteIdPut(vesselNoteId: string, vesselNoteUpdateModel?: VesselNoteUpdateModel, options?: any): AxiosPromise<VesselNoteModel> {
            return localVarFp.apiVesselnoteVesselNoteIdPut(vesselNoteId, vesselNoteUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesselnoteVesselVesselIdGet(vesselId: string, options?: any): AxiosPromise<Array<VesselNoteModel>> {
            return localVarFp.apiVesselnoteVesselVesselIdGet(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselNoteApi - object-oriented interface
 * @export
 * @class VesselNoteApi
 * @extends {BaseAPI}
 */
export class VesselNoteApi extends BaseAPI {
    /**
     * 
     * @param {string} [vesselId] 
     * @param {Array<any>} [evidence] 
     * @param {string} [title] 
     * @param {string} [note] 
     * @param {Array<VesselNoteEvidenceModel>} [vesselNoteEvidence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselNoteApi
     */
    public apiVesselnotePost(vesselId?: string, evidence?: Array<any>, title?: string, note?: string, vesselNoteEvidence?: Array<VesselNoteEvidenceModel>, options?: any) {
        return VesselNoteApiFp(this.configuration).apiVesselnotePost(vesselId, evidence, title, note, vesselNoteEvidence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselNoteApi
     */
    public apiVesselnoteVesselNoteIdDelete(vesselNoteId: string, options?: any) {
        return VesselNoteApiFp(this.configuration).apiVesselnoteVesselNoteIdDelete(vesselNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselNoteApi
     */
    public apiVesselnoteVesselNoteIdGet(vesselNoteId: string, options?: any) {
        return VesselNoteApiFp(this.configuration).apiVesselnoteVesselNoteIdGet(vesselNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselNoteId 
     * @param {VesselNoteUpdateModel} [vesselNoteUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselNoteApi
     */
    public apiVesselnoteVesselNoteIdPut(vesselNoteId: string, vesselNoteUpdateModel?: VesselNoteUpdateModel, options?: any) {
        return VesselNoteApiFp(this.configuration).apiVesselnoteVesselNoteIdPut(vesselNoteId, vesselNoteUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselNoteApi
     */
    public apiVesselnoteVesselVesselIdGet(vesselId: string, options?: any) {
        return VesselNoteApiFp(this.configuration).apiVesselnoteVesselVesselIdGet(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VesselTypeApi - axios parameter creator
 * @export
 */
export const VesselTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeDiscriminatorGet: async (discriminator: ContractType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'discriminator' is not null or undefined
            assertParamExists('apiVesseltypeDiscriminatorGet', 'discriminator', discriminator)
            const localVarPath = `/api/vesseltype/{discriminator}`
                .replace(`{${"discriminator"}}`, encodeURIComponent(String(discriminator)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesseltype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypePost: async (vesselTypeUpsertModel?: VesselTypeUpsertModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vesseltype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselTypeUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeVesselTypeIdDelete: async (vesselTypeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselTypeId' is not null or undefined
            assertParamExists('apiVesseltypeVesselTypeIdDelete', 'vesselTypeId', vesselTypeId)
            const localVarPath = `/api/vesseltype/{vesselTypeId}`
                .replace(`{${"vesselTypeId"}}`, encodeURIComponent(String(vesselTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeVesselTypeIdGet: async (vesselTypeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselTypeId' is not null or undefined
            assertParamExists('apiVesseltypeVesselTypeIdGet', 'vesselTypeId', vesselTypeId)
            const localVarPath = `/api/vesseltype/{vesselTypeId}`
                .replace(`{${"vesselTypeId"}}`, encodeURIComponent(String(vesselTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeVesselTypeIdPut: async (vesselTypeId: string, vesselTypeUpsertModel?: VesselTypeUpsertModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselTypeId' is not null or undefined
            assertParamExists('apiVesseltypeVesselTypeIdPut', 'vesselTypeId', vesselTypeId)
            const localVarPath = `/api/vesseltype/{vesselTypeId}`
                .replace(`{${"vesselTypeId"}}`, encodeURIComponent(String(vesselTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vesselTypeUpsertModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselTypeApi - functional programming interface
 * @export
 */
export const VesselTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseltypeDiscriminatorGet(discriminator: ContractType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseltypeDiscriminatorGet(discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseltypeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseltypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseltypePost(vesselTypeUpsertModel?: VesselTypeUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseltypePost(vesselTypeUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseltypeVesselTypeIdDelete(vesselTypeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseltypeVesselTypeIdDelete(vesselTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseltypeVesselTypeIdGet(vesselTypeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseltypeVesselTypeIdGet(vesselTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVesseltypeVesselTypeIdPut(vesselTypeId: string, vesselTypeUpsertModel?: VesselTypeUpsertModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVesseltypeVesselTypeIdPut(vesselTypeId, vesselTypeUpsertModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselTypeApi - factory interface
 * @export
 */
export const VesselTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {ContractType} discriminator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeDiscriminatorGet(discriminator: ContractType, options?: any): AxiosPromise<Array<VesselTypeModel>> {
            return localVarFp.apiVesseltypeDiscriminatorGet(discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeGet(options?: any): AxiosPromise<Array<VesselTypeModel>> {
            return localVarFp.apiVesseltypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypePost(vesselTypeUpsertModel?: VesselTypeUpsertModel, options?: any): AxiosPromise<VesselTypeModel> {
            return localVarFp.apiVesseltypePost(vesselTypeUpsertModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeVesselTypeIdDelete(vesselTypeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVesseltypeVesselTypeIdDelete(vesselTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeVesselTypeIdGet(vesselTypeId: string, options?: any): AxiosPromise<VesselTypeModel> {
            return localVarFp.apiVesseltypeVesselTypeIdGet(vesselTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vesselTypeId 
         * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVesseltypeVesselTypeIdPut(vesselTypeId: string, vesselTypeUpsertModel?: VesselTypeUpsertModel, options?: any): AxiosPromise<VesselModel> {
            return localVarFp.apiVesseltypeVesselTypeIdPut(vesselTypeId, vesselTypeUpsertModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselTypeApi - object-oriented interface
 * @export
 * @class VesselTypeApi
 * @extends {BaseAPI}
 */
export class VesselTypeApi extends BaseAPI {
    /**
     * 
     * @param {ContractType} discriminator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselTypeApi
     */
    public apiVesseltypeDiscriminatorGet(discriminator: ContractType, options?: any) {
        return VesselTypeApiFp(this.configuration).apiVesseltypeDiscriminatorGet(discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselTypeApi
     */
    public apiVesseltypeGet(options?: any) {
        return VesselTypeApiFp(this.configuration).apiVesseltypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselTypeApi
     */
    public apiVesseltypePost(vesselTypeUpsertModel?: VesselTypeUpsertModel, options?: any) {
        return VesselTypeApiFp(this.configuration).apiVesseltypePost(vesselTypeUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselTypeApi
     */
    public apiVesseltypeVesselTypeIdDelete(vesselTypeId: string, options?: any) {
        return VesselTypeApiFp(this.configuration).apiVesseltypeVesselTypeIdDelete(vesselTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselTypeApi
     */
    public apiVesseltypeVesselTypeIdGet(vesselTypeId: string, options?: any) {
        return VesselTypeApiFp(this.configuration).apiVesseltypeVesselTypeIdGet(vesselTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vesselTypeId 
     * @param {VesselTypeUpsertModel} [vesselTypeUpsertModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselTypeApi
     */
    public apiVesseltypeVesselTypeIdPut(vesselTypeId: string, vesselTypeUpsertModel?: VesselTypeUpsertModel, options?: any) {
        return VesselTypeApiFp(this.configuration).apiVesseltypeVesselTypeIdPut(vesselTypeId, vesselTypeUpsertModel, options).then((request) => request(this.axios, this.basePath));
    }
}


