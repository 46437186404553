

















































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseDataTable extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "Create" }) private btnText!: string;
  @Prop({ type: Boolean, default: false }) private noSearch!: boolean;
  @Prop({ type: Boolean, default: false }) private noCreate!: boolean;
  @Prop({ type: Boolean, default: false }) private exportBtn!: boolean;
  @Prop({ type: Boolean, default: false }) private noCard!: boolean;

  private search = "";
}
